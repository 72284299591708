/* in a near future when SCSS is implemented we could set the font-sizes and colors as variables  */

/* FONTS  */

@font-face {
  font-family: "FuturaPT-Heavy";
  src: url("./fonts/futura-pt-heavy.eot"); /* IE9 Compat Modes */
  src: url("./fonts/futura-pt-heavy.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/futura-pt-heavy.otf") format("opentype"),
    /* Open Type Font */ url("./fonts/futura-pt-heavy.svg") format("svg"),
    /* Legacy iOS */ url("./fonts/futura-pt-heavy.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/futura-pt-heavy.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/futura-pt-heavy.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "FuturaPT-Book";
  src: url("./fonts/futura-pt-book.eot"); /* IE9 Compat Modes */
  src: url("./fonts/futura-pt-book.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/futura-pt-book.otf") format("opentype"),
    /* Open Type Font */ url("./fonts/futura-pt-book.svg") format("svg"),
    /* Legacy iOS */ url("./fonts/futura-pt-book.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/futura-pt-book.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/futura-pt-book.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

.f-font,
.f-font.f-xs {
  font-family: "FuturaPT-Book";
  font-size: 16px;
}

.f-font.f-sm {
  font-size: 18px;
}

.f-font.f-md {
  font-size: 20px;
}

.f-font.f-lg {
  font-size: 22px;
}

.f-font h1,
.f-font h2,
.f-font h3,
.f-font h4,
.f-font h5,
h1.f-font,
h2.f-font,
h3.f-font,
h4.f-font,
h5.f-font {
  font-family: "FuturaPT-Book";
}

.f-font h1,
h1.f-font {
  font-size: 37px;
  text-transform: uppercase;
}

.f-font h2,
h2.f-font {
  font-size: 25px;
}

.f-font h3,
h3.f-font {
  font-size: 20px;
}

.f-font h4,
h4.f-font {
  font-size: 16px;
}

.f-font h5,
h5.f-font {
  font-size: 14px;
}

.f-font .title a,
.title.f-font a {
  color: #000;
}

.f-font .f-uppercase,
.f-uppercase.f-font {
  text-transform: uppercase;
}

.f-font strong,
strong.f-font,
.f-font b,
b.f-font,
.f-font .f-bold,
.f-bold.f-font {
  font-family: "FuturaPT-Heavy";
}

.f-font .f-oblique,
.f-oblique.f-font {
  font-style: italic;
}

.f-font small,
small.f-font {
  font-size: 14px;
  color: #888;
}

.f-font a,
a.f-font {
  color: #336699;
  text-decoration: none;
}
.f-font a:hover,
a.f-font:hover {
  text-decoration: underline;
}

.f-font label,
label.f-font {
  text-transform: uppercase;
  font-family: "FuturaPT-Heavy";
}

.f-font code,
.f-font .code,
code.f-font,
.code.f-font {
  font-family: "Courier New";
  text-transform: lowercase;
  color: #000;
  background-color: transparent;
}

/* Rewriting bootstrap or old b2bsite defaults */
.f-font .form-control,
.form-control.f-font,
.f-font .input-group-addon,
.input-group-addon.f-font,
.f-font .btn,
.btn.f-font,
.f-font .widget-like > header h2,
.widget-like.f-font > header h2,
.f-font .widget-like .widget-body,
.widget-like.f-font .widget-body,
.f-font .widget-like .widget-body .data-header,
.widget-like.f-font .widget-body .data-header {
  font-family: "FuturaPT-Book";
  font-size: 16px;
  font-weight: normal;
}
.f-font .dropdown-menu > li > a,
.dropdown-menu.f-font > li > a {
  font-size: 16px;
}
.dropdown-menu a:hover .f-icon.f-xs:before {
  background-position-y: -18px;
}
.dropdown-menu a:hover .f-icon.f-sm:before {
  background-position-y: -20px;
}

/* FMIC Buttons */

.f-btn {
  font-family: "FuturaPT-Book";
  display: inline-block;
  padding: 8px 28px;
  margin: 0;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 7px;
}
.f-btn:hover {
  text-decoration: none !important;
}

/* Bootstrap classes with buttons */
.input-group-btn .f-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 4px 16px;
}
.f-font .form-control {
  height: 35px;
}

.f-btn:disabled,
.f-btn:hover:disabled {
  background-color: #a1a1a1 !important;
  border-color: #a1a1a1 !important;
  color: #dbdbdb !important;
}

.f-btn i {
  display: inline-block;
}

.f-btn .badge {
  background-color: transparent;
  border: solid 1px #fff;
  color: #fff;
  border-radius: 20px;
  line-height: 0.8em;
  border-radius: 1.2em;
  font-weight: initial;
  position: relative;
  top: -1px;
}
.f-btn a {
  text-decoration: none;
}

.f-btn.f-xs {
  font-size: 16px;
  padding: 5px 16px;
}
.f-btn.f-xs img {
  width: auto;
  height: 14px;
  margin-right: 16px;
}
.f-btn.f-xs i {
  margin-right: 16px;
}
.f-btn.f-xs .badge {
  font-size: 14px;
  padding: 4px 5px 2px;
  margin-right: 16px;
}

@media (max-width: 479px) {
  .f-btn i {
    margin: 0 2px;
  }
}

.f-btn.f-sm {
  font-size: 18px;
  padding: 7px 18px;
}
.f-btn.f-sm img {
  width: auto;
  height: 17px;
  margin-right: 18px;
}
.f-btn.f-sm i {
  margin-right: 18px;
}

.f-btn.f-sm .badge {
  font-size: 16px;
  padding: 5px 5px 2px;
  margin-right: 18px;
}

.f-btn.f-md {
  font-size: 20px;
  padding: 12px 20px;
}
.f-btn.f-md img {
  width: auto;
  height: 19px;
  margin-right: 20px;
}
.f-btn.f-md i {
  margin-right: 20px;
}
.f-btn.f-md .badge {
  font-size: 16px;
  padding: 5px 6px 2px;
  margin-right: 20px;
}

.f-btn.f-lg {
  padding: 16px 44px;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.f-btn.f-lg img {
  width: auto;
  height: 20px;
  margin-right: 22px;
}
.f-btn.f-lg i {
  margin-right: 22px;
}

.f-btn.f-lg .badge {
  font-size: 20px;
  padding: 7px 7px 4px 8px;
  margin-right: 22px;
}

.f-btn.f-blue {
  background: #26598e;
  border-color: #26598e;
  color: #fff !important;
}
.f-btn.f-blue:hover {
  background: #4376ac;
  border-color: #4376ac;
}
.f-btn.f-blue:hover,
.f-btn.f-blue:focus,
.f-btn.f-blue:active {
  color: #fff !important;
}

.f-btn.f-red {
  background: #cc3333;
  border-color: #cc3333;
  color: #fff !important;
}
.f-btn.f-red:hover {
  background: #f02525;
  border-color: #f02525;
}
.f-btn.f-red:hover,
.f-btn.f-red:focus,
.f-btn.f-red:active {
  color: #fff !important;
}

.f-btn.f-black {
  background: #252525;
  border-color: #252525;
  color: #fff !important;
}
.f-btn.f-black:hover {
  background: #404040;
  border-color: #404040;
}
.f-btn.f-black:hover,
.f-btn.f-black:focus,
.f-btn.f-black:active {
  color: #fff !important;
}

.f-btn.f-yellow {
  background: #f2c027;
  border-color: #f2c027;
  color: #000 !important;
}
.f-btn.f-yellow:hover {
  background: #ffcc33;
  border-color: #ffcc33;
}
.f-btn.f-yellow:hover,
.f-btn.f-yellow:focus,
.f-btn.f-yellow:active {
  color: #000 !important;
}

.f-btn.f-green {
  background: #66a559;
  border-color: #66a559;
  color: #fff !important;
}
.f-btn.f-green:hover {
  background: #74b566;
  border-color: #74b566;
}
.f-btn.f-green:hover,
.f-btn.f-green:focus,
.f-btn.f-green:active {
  color: #fff !important;
}

/* FENDER NEW ICONS - This section will be refactored with the .f-icon classes below */
.fmic-icons {
  width: 18px;
  height: 18px;
  display: inline-block;
}

.fmic-icons-filter {
  background: url(./images/product-search-filter.svg) no-repeat;
}

.fmic-icons-plus {
  background: url(./images/product-search-plus.svg) no-repeat;
}

.fmic-icons-minus {
  background: url(./images/product-search-minus.svg) no-repeat;
}

.fmic-icons-circle-x {
  background: url(./images/product-search-round-x.svg) no-repeat;
}

.fmic-icons-chevron-up {
  background: url(./images/product-search-chevron-up.svg) no-repeat;
}

.fmic-icons-chevron-down {
  background: url(./images/product-search-chevron-down.svg) no-repeat;
}

.fmic-icons-shopping-cart-arrow-down {
  background: url(./images/shopping-cart-arrow-down.svg) no-repeat;
}

/* FMIC Iconography */

.f-icon {
  width: 18px;
  height: 18px;
  display: inline-block;
}
.f-icon.f-sm {
  width: 18px;
  height: 18px;
}
.f-icon.f-md {
  width: 22px;
  height: 22px;
}
.f-icon.f-lg {
  width: 26px;
  height: 26px;
}

.f-icon:before {
  content: " ";
  background: url(./images/fmic-icon-vector.svg) no-repeat;
  display: inline-block;
  background-position: 0 0;
  width: 18px;
  height: 18px;
  position: relative;
  top: 4px;
}
.f-icon.f-white:before {
  background-position-y: -18px;
}

/* FMIC Iconography within Button Colors */

.f-btn.f-yellow .f-icon:before,
.f-white .f-icon:before {
  background-position-y: 0;
}

.f-btn.f-red.f-xss .f-icon:before,
.f-btn.f-black.f-xss .f-icon:before,
.f-btn.f-green.f-xss .f-icon:before,
.f-btn.f-blue.f-xss .f-icon:before {
  background-position-y: -16px;
}
.f-btn.f-red .f-icon:before,
.f-btn.f-black .f-icon:before,
.f-btn.f-green .f-icon:before,
.f-btn.f-blue .f-icon:before {
  background-position-y: -18px;
}
.f-btn.f-red.f-sm .f-icon:before,
.f-btn.f-black.f-sm .f-icon:before,
.f-btn.f-green.f-sm .f-icon:before,
.f-btn.f-blue.f-sm .f-icon:before {
  background-position-y: -20px;
}
.f-btn.f-red.f-md .f-icon:before,
.f-btn.f-black.f-md .f-icon:before,
.f-btn.f-green.f-md .f-icon:before,
.f-btn.f-blue.f-md .f-icon:before {
  background-position-y: -22px;
}
.f-btn.f-red.f-lg .f-icon:before,
.f-btn.f-black.f-lg .f-icon:before,
.f-btn.f-green.f-lg .f-icon:before,
.f-btn.f-blue.f-lg .f-icon:before {
  background-position-y: -26px;
}

/* Iconography within Buttons Sizes */
.f-btn.f-xss .f-icon {
  width: 16px;
  height: 16px;
}
.f-btn.f-xss .f-icon:before,
.f-icon.f-xss:before {
  background-size: auto 64px;
  width: 16px;
  height: 16px;
}
.f-btn.f-xss .f-icon.f-white:before,
.f-xss.f-icon.f-white:before {
  background-position-y: -16px;
}

.f-btn.f-xs .f-icon {
  width: 18px;
  height: 18px;
}
.f-btn.f-xs .f-icon:before,
.f-icon.f-xs:before {
  width: 18px;
  height: 18px;
}
.f-btn.f-xs .f-icon.f-white:before,
.f-xs.f-icon.f-white:before {
  background-position-y: -18px;
}

.f-btn.f-sm .f-icon {
  width: 20px;
  height: 20px;
}
.f-btn.f-sm .f-icon:before,
.f-icon.f-sm:before {
  background-size: auto 80px;
  width: 20px;
  height: 20px;
}
.f-btn.f-sm .f-icon.f-white:before,
.f-sm.f-icon.f-white:before {
  background-position-y: -20px;
}

.f-btn.f-md .f-icon {
  width: 22px;
  height: 22px;
}
.f-btn.f-md .f-icon:before,
.f-icon.f-md:before {
  background-size: auto 88px;
  width: 22px;
  height: 22px;
}
.f-btn.f-md .f-icon.f-white:before,
.f-md.f-icon.f-white:before {
  background-position-y: -22px;
}

.f-btn.f-lg .f-icon {
  width: 26px;
  height: 26px;
}
.f-btn.f-lg .f-icon:before,
.f-icon.f-lg:before {
  background-size: auto 104px;
  width: 26px;
  height: 26px;
}
.f-btn.f-xl .f-icon {
  width: 37px;
  height: 37px;
}
.f-btn.f-xl .f-icon:before,
.f-icon.f-xl:before {
  background-size: auto 148px;
  width: 37px;
  height: 37px;
}
.f-btn.f-lg .f-icon.f-white:before,
.f-lg.f-icon.f-white:before {
  background-position-y: -26px;
}

/* Icon Specific */

.f-icon.f-shopping-cart-add:before,
.f-btn.f-xs .f-shopping-cart-add:before,
.f-btn.f-xs.f-shopping-cart-add:before,
.f-btn.f-sm .f-shopping-cart-add:before,
.f-btn.f-sm.f-shopping-cart-add:before,
.f-btn.f-md .f-shopping-cart-add:before,
.f-btn.f-md.f-shopping-cart-add:before,
.f-btn.f-lg .f-shopping-cart-add:before,
.f-btn.f-lg.f-shopping-cart-add:before {
  background-position-x: 0;
}

.f-btn.f-xss .f-icon.f-shopping-cart-remove:before,
.f-icon.f-xss.f-shopping-cart-remove:before {
  background-position-x: -16px;
}
.f-icon.f-shopping-cart-remove:before,
.f-btn.f-xs .f-icon.f-shopping-cart-remove:before,
.f-icon.f-xs.f-shopping-cart-remove:before {
  background-position-x: -18px;
}
.f-btn.f-sm .f-icon.f-shopping-cart-remove:before,
.f-icon.f-sm.f-shopping-cart-remove:before {
  background-position-x: -20px;
}
.f-btn.f-md .f-icon.f-shopping-cart-remove:before,
.f-icon.f-md.f-shopping-cart-remove:before {
  background-position-x: -22px;
}
.f-btn.f-lg .f-icon.f-shopping-cart-remove:before,
.f-icon.f-lg.f-shopping-cart-remove:before {
  background-position-x: -26px;
}

.f-btn.f-xss .f-icon.f-shopping-cart:before,
.f-icon.f-xss.f-shopping-cart:before {
  background-position-x: -32px;
}
.f-icon.f-shopping-cart:before,
.f-btn.f-xs .f-icon.f-shopping-cart:before,
.f-icon.f-xs.f-shopping-cart:before {
  background-position-x: -36px;
}
.f-btn.f-sm .f-icon.f-shopping-cart:before,
.f-icon.f-sm.f-shopping-cart:before {
  background-position-x: -40px;
}
.f-btn.f-md .f-icon.f-shopping-cart:before,
.f-icon.f-md.f-shopping-cart:before {
  background-position-x: -44px;
}
.f-btn.f-lg .f-icon.f-shopping-cart:before,
.f-icon.f-lg.f-shopping-cart:before {
  background-position-x: -52px;
}

.f-btn.f-xss .f-icon.f-pdf-file:before,
.f-icon.f-xss.f-pdf-file:before {
  background-position-x: -48px;
}
.f-icon.f-pdf-file:before,
.f-btn.f-xs .f-icon.f-pdf-file:before,
.f-icon.f-xs.f-pdf-file:before {
  background-position-x: -54px;
}
.f-btn.f-sm .f-icon.f-pdf-file:before,
.f-icon.f-sm.f-pdf-file:before {
  background-position-x: -60px;
}
.f-btn.f-md .f-icon.f-pdf-file:before,
.f-icon.f-md.f-pdf-file:before {
  background-position-x: -66px;
}
.f-btn.f-lg .f-icon.f-pdf-file:before,
.f-icon.f-lg.f-pdf-file:before {
  background-position-x: -78px;
}

.f-btn.f-xss .f-icon.f-bucket:before,
.f-icon.f-xss.f-bucket:before {
  background-position-x: -64px;
}
.f-icon.f-bucket:before,
.f-btn.f-xs .f-icon.f-bucket:before,
.f-icon.f-xs.f-bucket:before {
  background-position-x: -72px;
}
.f-btn.f-sm .f-icon.f-bucket:before,
.f-icon.f-sm.f-bucket:before {
  background-position-x: -80px;
}
.f-btn.f-md .f-icon.f-bucket:before,
.f-icon.f-md.f-bucket:before {
  background-position-x: -88px;
}
.f-btn.f-lg .f-icon.f-bucket:before,
.f-icon.f-lg.f-bucket:before {
  background-position-x: -104px;
}

.f-btn.f-xss .f-icon.f-dollar-sign:before,
.f-icon.f-xss.f-dollar-sign:before {
  background-position-x: -80px;
}
.f-icon.f-dollar-sign:before,
.f-btn.f-xs .f-icon.f-dollar-sign:before,
.f-icon.f-xs.f-dollar-sign:before {
  background-position-x: -90px;
}
.f-btn.f-sm .f-icon.f-dollar-sign:before,
.f-icon.f-sm.f-dollar-sign:before {
  background-position-x: -100px;
}
.f-btn.f-md .f-icon.f-dollar-sign:before,
.f-icon.f-md.f-dollar-sign:before {
  background-position-x: -110px;
}
.f-btn.f-lg .f-icon.f-dollar-sign:before,
.f-icon.f-lg.f-dollar-sign:before {
  background-position-x: -130px;
}

.f-btn.f-xss .f-icon.f-bid:before,
.f-icon.f-xss.f-bid:before {
  background-position-x: -96px;
}
.f-icon.f-bid:before,
.f-btn.f-xs .f-icon.f-bid:before,
.f-icon.f-xs.f-bid:before {
  background-position-x: -108px;
}
.f-btn.f-sm .f-icon.f-bid:before,
.f-icon.f-sm.f-bid:before {
  background-position-x: -120px;
}
.f-btn.f-md .f-icon.f-bid:before,
.f-icon.f-md.f-bid:before {
  background-position-x: -132px;
}
.f-btn.f-lg .f-icon.f-bid:before,
.f-icon.f-lg.f-bid:before {
  background-position-x: -156px;
}

.f-btn.f-xss .f-icon.f-video:before,
.f-icon.f-xss.f-video:before {
  background-position-x: -112px;
}
.f-icon.f-video:before,
.f-btn.f-xs .f-icon.f-video:before,
.f-icon.f-xs.f-video:before {
  background-position-x: -126px;
}
.f-btn.f-sm .f-icon.f-video:before,
.f-icon.f-sm.f-video:before {
  background-position-x: -140px;
}
.f-btn.f-md .f-icon.f-video:before,
.f-icon.f-md.f-video:before {
  background-position-x: -154px;
}
.f-btn.f-lg .f-icon.f-video:before,
.f-icon.f-lg.f-video:before {
  background-position-x: -182px;
}

.f-btn.f-xss .f-icon.f-chevron-up:before,
.f-icon.f-xss.f-chevron-up:before {
  background-position-x: -128px;
}
.f-icon.f-chevron-up:before,
.f-btn.f-xs .f-icon.f-chevron-up:before,
.f-icon.f-xs.f-chevron-up:before {
  background-position-x: -144px;
}
.f-btn.f-sm .f-icon.f-chevron-up:before,
.f-icon.f-sm.f-chevron-up:before {
  background-position-x: -160px;
}
.f-btn.f-md .f-icon.f-chevron-up:before,
.f-icon.f-md.f-chevron-up:before {
  background-position-x: -176px;
}
.f-btn.f-lg .f-icon.f-chevron-up:before,
.f-icon.f-lg.f-chevron-up:before {
  background-position-x: -208px;
}

.f-btn.f-xss .f-icon.f-chevron-down:before,
.f-icon.f-xss.f-chevron-down:before {
  background-position-x: -144px;
}
.f-icon.f-chevron-down:before,
.f-btn.f-xs .f-icon.f-chevron-down:before,
.f-icon.f-xs.f-chevron-down:before {
  background-position-x: -162px;
}
.f-btn.f-sm .f-icon.f-chevron-down:before,
.f-icon.f-sm.f-chevron-down:before {
  background-position-x: -180px;
}
.f-btn.f-md .f-icon.f-chevron-down:before,
.f-icon.f-md.f-chevron-down:before {
  background-position-x: -198px;
}
.f-btn.f-lg .f-icon.f-chevron-down:before,
.f-icon.f-lg.f-chevron-down:before {
  background-position-x: -234px;
}

.f-btn.f-xss .f-icon.f-image:before,
.f-icon.f-xss.f-image:before {
  background-position-x: -160px;
}
.f-icon.f-image:before,
.f-btn.f-xs .f-icon.f-image:before,
.f-icon.f-xs.f-image:before {
  background-position-x: -180px;
}
.f-btn.f-sm .f-icon.f-image:before,
.f-icon.f-sm.f-image:before {
  background-position-x: -200px;
}
.f-btn.f-md .f-icon.f-image:before,
.f-icon.f-md.f-image:before {
  background-position-x: -220px;
}
.f-btn.f-lg .f-icon.f-image:before,
.f-icon.f-lg.f-image:before {
  background-position-x: -260px;
}

.f-btn.f-xss .f-icon.f-backslash:before,
.f-icon.f-xss.f-backslash:before {
  background-position-x: -176px;
}
.f-icon.f-backslash:before,
.f-btn.f-xs .f-icon.f-backslash:before,
.f-icon.f-xs.f-backslash:before {
  background-position-x: -198px;
}
.f-btn.f-sm .f-icon.f-backslash:before,
.f-icon.f-sm.f-backslash:before {
  background-position-x: -220px;
}
.f-btn.f-md .f-icon.f-backslash:before,
.f-icon.f-md.f-backslash:before {
  background-position-x: -242px;
}
.f-btn.f-lg .f-icon.f-backslash:before,
.f-icon.f-lg.f-backslash:before {
  background-position-x: -286px;
}

.f-btn.f-xss .f-icon.f-x:before,
.f-icon.f-xss.f-x:before {
  background-position-x: -192px;
}
.f-icon.f-x:before,
.f-btn.f-xs .f-icon.f-x:before,
.f-icon.f-xs.f-x:before {
  background-position-x: -216px;
}
.f-btn.f-sm .f-icon.f-x:before,
.f-icon.f-sm.f-x:before {
  background-position-x: -240px;
}
.f-btn.f-md .f-icon.f-x:before,
.f-icon.f-md.f-x:before {
  background-position-x: -264px;
}
.f-btn.f-lg .f-icon.f-x:before,
.f-icon.f-lg.f-x:before {
  background-position-x: -312px;
}

.f-btn.f-xss .f-icon.f-circled-x:before,
.f-icon.f-xss.f-circled-x:before {
  background-position-x: -208px;
}
.f-icon.f-circled-x:before,
.f-btn.f-xs .f-icon.f-circled-x:before,
.f-icon.f-xs.f-circled-x:before {
  background-position-x: -234px;
}
.f-btn.f-sm .f-icon.f-circled-x:before,
.f-icon.f-sm.f-circled-x:before {
  background-position-x: -260px;
}
.f-btn.f-md .f-icon.f-circled-x:before,
.f-icon.f-md.f-circled-x:before {
  background-position-x: -286px;
}
.f-btn.f-lg .f-icon.f-circled-x:before,
.f-icon.f-lg.f-circled-x:before {
  background-position-x: -338px;
}
.f-btn.f-xl .f-icon.f-circled-x:before,
.f-icon.f-xl.f-circled-x:before {
  background-position-x: -481px;
}

.f-btn.f-xss .f-icon.f-pencil:before,
.f-icon.f-xss.f-pencil:before {
  background-position-x: -224px;
}
.f-icon.f-pencil:before,
.f-btn.f-xs .f-icon.f-pencil:before,
.f-icon.f-xs.f-pencil:before {
  background-position-x: -252px;
}
.f-btn.f-sm .f-icon.f-pencil:before,
.f-icon.f-sm.f-pencil:before {
  background-position-x: -280px;
}
.f-btn.f-md .f-icon.f-pencil:before,
.f-icon.f-md.f-pencil:before {
  background-position-x: -308px;
}
.f-btn.f-lg .f-icon.f-pencil:before,
.f-icon.f-lg.f-pencil:before {
  background-position-x: -364px;
}

.f-btn.f-xss .f-icon.f-search:before,
.f-icon.f-xss.f-search:before {
  background-position-x: -240px;
}
.f-icon.f-search:before,
.f-btn.f-xs .f-icon.f-search:before,
.f-icon.f-xs.f-search:before {
  background-position-x: -270px;
}
.f-btn.f-sm .f-icon.f-search:before,
.f-icon.f-sm.f-search:before {
  background-position-x: -300px;
}
.f-btn.f-md .f-icon.f-search:before,
.f-icon.f-md.f-search:before {
  background-position-x: -330px;
}
.f-btn.f-lg .f-icon.f-search:before,
.f-icon.f-lg.f-search:before {
  background-position-x: -390px;
}

.f-btn.f-xss .f-icon.f-blocks:before,
.f-icon.f-xss.f-blocks:before {
  background-position-x: -256px;
}
.f-icon.f-blocks:before,
.f-btn.f-xs .f-icon.f-blocks:before,
.f-icon.f-xs.f-blocks:before {
  background-position-x: -288px;
}
.f-btn.f-sm .f-icon.f-blocks:before,
.f-icon.f-sm.f-blocks:before {
  background-position-x: -320px;
}
.f-btn.f-md .f-icon.f-blocks:before,
.f-icon.f-md.f-blocks:before {
  background-position-x: -352px;
}
.f-btn.f-lg .f-icon.f-blocks:before,
.f-icon.f-lg.f-blocks:before {
  background-position-x: -416px;
}

.f-btn.f-xss .f-icon.f-revert:before,
.f-icon.f-xss.f-revert:before {
  background-position-x: -272px;
}
.f-icon.f-revert:before,
.f-btn.f-xs .f-icon.f-revert:before,
.f-icon.f-xs.f-revert:before {
  background-position-x: -306px;
}
.f-btn.f-sm .f-icon.f-revert:before,
.f-icon.f-sm.f-revert:before {
  background-position-x: -340px;
}
.f-btn.f-md .f-icon.f-revert:before,
.f-icon.f-md.f-revert:before {
  background-position-x: -374px;
}
.f-btn.f-lg .f-icon.f-revert:before,
.f-icon.f-lg.f-revert:before {
  background-position-x: -442px;
}

.f-btn.f-xss .f-icon.f-first-page-arrowheads:before,
.f-icon.f-xss.f-first-page-arrowheads:before {
  background-position-x: -288px;
}
.f-icon.f-first-page-arrowheads:before,
.f-btn.f-xs .f-icon.f-first-page-arrowheads:before,
.f-icon.f-xs.f-first-page-arrowheads:before {
  background-position-x: -324px;
}
.f-btn.f-sm .f-icon.f-first-page-arrowheads:before,
.f-icon.f-sm.f-first-page-arrowheads:before {
  background-position-x: -360px;
}
.f-btn.f-md .f-icon.f-first-page-arrowheads:before,
.f-icon.f-md.f-first-page-arrowheads:before {
  background-position-x: -396px;
}
.f-btn.f-lg .f-icon.f-first-page-arrowheads:before,
.f-icon.f-lg.f-first-page-arrowheads:before {
  background-position-x: -468px;
}

.f-btn.f-xss .f-icon.f-last-page-arrowheads:before,
.f-icon.f-xss.f-last-page-arrowheads:before {
  background-position-x: -304px;
}
.f-icon.f-last-page-arrowheads:before,
.f-btn.f-xs .f-icon.f-last-page-arrowheads:before,
.f-icon.f-xs.f-last-page-arrowheads:before {
  background-position-x: -342px;
}
.f-btn.f-sm .f-icon.f-last-page-arrowheads:before,
.f-icon.f-sm.f-last-page-arrowheads:before {
  background-position-x: -380px;
}
.f-btn.f-md .f-icon.f-last-page-arrowheads:before,
.f-icon.f-md.f-last-page-arrowheads:before {
  background-position-x: -418px;
}
.f-btn.f-lg .f-icon.f-last-page-arrowheads:before,
.f-icon.f-lg.f-last-page-arrowheads:before {
  background-position-x: -494px;
}

.f-btn.f-xss .f-icon.f-prior-page-arrowhead:before,
.f-icon.f-xss.f-prior-page-arrowhead:before {
  background-position-x: -320px;
}
.f-icon.f-prior-page-arrowhead:before,
.f-btn.f-xs .f-icon.f-prior-page-arrowhead:before,
.f-icon.f-xs.f-prior-page-arrowhead:before {
  background-position-x: -360px;
}
.f-btn.f-sm .f-icon.f-prior-page-arrowhead:before,
.f-icon.f-sm.f-prior-page-arrowhead:before {
  background-position-x: -400px;
}
.f-btn.f-md .f-icon.f-prior-page-arrowhead:before,
.f-icon.f-md.f-prior-page-arrowhead:before {
  background-position-x: -440px;
}
.f-btn.f-lg .f-icon.f-prior-page-arrowhead:before,
.f-icon.f-lg.f-prior-page-arrowhead:before {
  background-position-x: -520px;
}

.f-btn.f-xss .f-icon.f-next-page-arrowhead:before,
.f-icon.f-xss.f-next-page-arrowhead:before {
  background-position-x: -336px;
}
.f-icon.f-next-page-arrowhead:before,
.f-btn.f-xs .f-icon.f-next-page-arrowhead:before,
.f-icon.f-xs.f-next-page-arrowhead:before {
  background-position-x: -378px;
}
.f-btn.f-sm .f-icon.f-next-page-arrowhead:before,
.f-icon.f-sm.f-next-page-arrowhead:before {
  background-position-x: -420px;
}
.f-btn.f-md .f-icon.f-next-page-arrowhead:before,
.f-icon.f-md.f-next-page-arrowhead:before {
  background-position-x: -462px;
}
.f-btn.f-lg .f-icon.f-next-page-arrowhead:before,
.f-icon.f-lg.f-next-page-arrowhead:before {
  background-position-x: -546px;
}

.f-btn.f-xss .f-icon.f-restore:before,
.f-icon.f-xss.f-restore:before {
  background-position-x: -352px;
}
.f-icon.f-restore:before,
.f-btn.f-xs .f-icon.f-restore:before,
.f-icon.f-xs.f-restore:before {
  background-position-x: -396px;
}
.f-btn.f-sm .f-icon.f-restore:before,
.f-icon.f-sm.f-restore:before {
  background-position-x: -440px;
}
.f-btn.f-md .f-icon.f-restore:before,
.f-icon.f-md.f-restore:before {
  background-position-x: -484px;
}
.f-btn.f-lg .f-icon.f-restore:before,
.f-icon.f-lg.f-restore:before {
  background-position-x: -572px;
}

.f-btn.f-xss .f-icon.f-fast-box:before,
.f-icon.f-xss.f-fast-box:before {
  background-position-x: -368px;
}
.f-icon.f-fast-box:before,
.f-btn.f-xs .f-icon.f-fast-box:before,
.f-icon.f-xs.f-fast-box:before {
  background-position-x: -414px;
}
.f-btn.f-sm .f-icon.f-fast-box:before,
.f-icon.f-sm.f-fast-box:before {
  background-position-x: -460px;
}
.f-btn.f-md .f-icon.f-fast-box:before,
.f-icon.f-md.f-fast-box:before {
  background-position-x: -506px;
}
.f-btn.f-lg .f-icon.f-fast-box:before,
.f-icon.f-lg.f-fast-box:before {
  background-position-x: -598px;
}

.f-btn.f-xss .f-icon.f-calendar:before,
.f-icon.f-xss.f-calendar:before {
  background-position-x: -384px;
}
.f-icon.f-calendar:before,
.f-btn.f-xs .f-icon.f-calendar:before,
.f-icon.f-xs.f-calendar:before {
  background-position-x: -432px;
}
.f-btn.f-sm .f-icon.f-calendar:before,
.f-icon.f-sm.f-calendar:before {
  background-position-x: -480px;
}
.f-btn.f-md .f-icon.f-calendar:before,
.f-icon.f-md.f-calendar:before {
  background-position-x: -528px;
}
.f-btn.f-lg .f-icon.f-calendar:before,
.f-icon.f-lg.f-calendar:before {
  background-position-x: -624px;
}

.f-btn.f-xss .f-icon.f-checkmark:before,
.f-icon.f-xss.f-checkmark:before {
  background-position-x: -400px;
}
.f-icon.f-checkmark:before,
.f-btn.f-xs .f-icon.f-checkmark:before,
.f-icon.f-xs.f-checkmark:before {
  background-position-x: -450px;
}
.f-btn.f-sm .f-icon.f-checkmark:before,
.f-icon.f-sm.f-checkmark:before {
  background-position-x: -500px;
}
.f-btn.f-md .f-icon.f-checkmark:before,
.f-icon.f-md.f-checkmark:before {
  background-position-x: -550px;
}
.f-btn.f-lg .f-icon.f-checkmark:before,
.f-icon.f-lg.f-checkmark:before {
  background-position-x: -650px;
}

.f-btn.f-xss .f-icon.f-download-arrow:before,
.f-icon.f-xss.f-download-arrow:before {
  background-position-x: -416px;
}
.f-icon.f-download-arrow:before,
.f-btn.f-xs .f-icon.f-download-arrow:before,
.f-icon.f-xs.f-download-arrow:before {
  background-position-x: -468px;
}
.f-btn.f-sm .f-icon.f-download-arrow:before,
.f-icon.f-sm.f-download-arrow:before {
  background-position-x: -520px;
}
.f-btn.f-md .f-icon.f-download-arrow:before,
.f-icon.f-md.f-download-arrow:before {
  background-position-x: -572px;
}
.f-btn.f-lg .f-icon.f-download-arrow:before,
.f-icon.f-lg.f-download-arrow:before {
  background-position-x: -676px;
}

.f-btn.f-xss .f-icon.f-video-camera:before,
.f-icon.f-xss.f-video-camera:before {
  background-position-x: -432px;
}
.f-icon.f-video-camera:before,
.f-btn.f-xs .f-icon.f-video-camera:before,
.f-icon.f-xs.f-video-camera:before {
  background-position-x: -486px;
}
.f-btn.f-sm .f-icon.f-video-camera:before,
.f-icon.f-sm.f-video-camera:before {
  background-position-x: -540px;
}
.f-btn.f-md .f-icon.f-video-camera:before,
.f-icon.f-md.f-video-camera:before {
  background-position-x: -594px;
}
.f-btn.f-lg .f-icon.f-video-camera:before,
.f-icon.f-lg.f-video-camera:before {
  background-position-x: -702px;
}

.f-btn.f-xss .f-icon.f-floppy:before,
.f-icon.f-xss.f-floppy:before {
  background-position-x: -448px;
}
.f-icon.f-floppy:before,
.f-btn.f-xs .f-icon.f-floppy:before,
.f-icon.f-xs.f-floppy:before {
  background-position-x: -504px;
}
.f-btn.f-sm .f-icon.f-floppy:before,
.f-icon.f-sm.f-floppy:before {
  background-position-x: -560px;
}
.f-btn.f-md .f-icon.f-floppy:before,
.f-icon.f-md.f-floppy:before {
  background-position-x: -616px;
}
.f-btn.f-lg .f-icon.f-floppy:before,
.f-icon.f-lg.f-floppy:before {
  background-position-x: -728px;
}

.f-btn.f-xss .f-icon.f-choose-file:before,
.f-icon.f-xss.f-choose-file:before {
  background-position-x: -464px;
}
.f-icon.f-choose-file:before,
.f-btn.f-xs .f-icon.f-choose-file:before,
.f-icon.f-xs.f-choose-file:before {
  background-position-x: -522px;
}
.f-btn.f-sm .f-icon.f-choose-file:before,
.f-icon.f-sm.f-choose-file:before {
  background-position-x: -580px;
}
.f-btn.f-md .f-icon.f-choose-file:before,
.f-icon.f-md.f-choose-file:before {
  background-position-x: -638px;
}
.f-btn.f-lg .f-icon.f-choose-file:before,
.f-icon.f-lg.f-choose-file:before {
  background-position-x: -754px;
}

.f-btn.f-xss .f-icon.f-upload:before,
.f-icon.f-xss.f-upload:before {
  background-position-x: -480px;
}
.f-icon.f-upload:before,
.f-btn.f-xs .f-icon.f-upload:before,
.f-icon.f-xs.f-upload:before {
  background-position-x: -540px;
}
.f-btn.f-sm .f-icon.f-upload:before,
.f-icon.f-sm.f-upload:before {
  background-position-x: -600px;
}
.f-btn.f-md .f-icon.f-upload:before,
.f-icon.f-md.f-upload:before {
  background-position-x: -660px;
}
.f-btn.f-lg .f-icon.f-upload:before,
.f-icon.f-lg.f-upload:before {
  background-position-x: -780px;
}

.f-btn.f-xss .f-icon.f-excel-file:before,
.f-icon.f-xss.f-excel-file:before {
  background-position-x: -496px;
}
.f-icon.f-excel-file:before,
.f-btn.f-xs .f-icon.f-excel-file:before,
.f-icon.f-xs.f-excel-file:before {
  background-position-x: -558px;
}
.f-btn.f-sm .f-icon.f-excel-file:before,
.f-icon.f-sm.f-excel-file:before {
  background-position-x: -620px;
}
.f-btn.f-md .f-icon.f-excel-file:before,
.f-icon.f-md.f-excel-file:before {
  background-position-x: -682px;
}
.f-btn.f-lg .f-icon.f-excel-file:before,
.f-icon.f-lg.f-excel-file:before {
  background-position-x: -806px;
}

.f-btn.f-xss .f-icon.f-squared-cross:before,
.f-icon.f-xss.f-squared-cross:before {
  background-position-x: -512px;
}
.f-icon.f-squared-cross:before,
.f-btn.f-xs .f-icon.f-squared-cross:before,
.f-icon.f-xs.f-squared-cross:before {
  background-position-x: -576px;
}
.f-btn.f-sm .f-icon.f-squared-cross:before,
.f-icon.f-sm.f-squared-cross:before {
  background-position-x: -640px;
}
.f-btn.f-md .f-icon.f-squared-cross:before,
.f-icon.f-md.f-squared-cross:before {
  background-position-x: -704px;
}
.f-btn.f-lg .f-icon.f-squared-cross:before,
.f-icon.f-lg.f-squared-cross:before {
  background-position-x: -832px;
}

.f-btn.f-xss .f-icon.f-print:before,
.f-icon.f-xss.f-print:before {
  background-position-x: -528px;
}
.f-icon.f-print:before,
.f-btn.f-xs .f-icon.f-print:before,
.f-icon.f-xs.f-print:before {
  background-position-x: -594px;
}
.f-btn.f-sm .f-icon.f-print:before,
.f-icon.f-sm.f-print:before {
  background-position-x: -660px;
}
.f-btn.f-md .f-icon.f-print:before,
.f-icon.f-md.f-print:before {
  background-position-x: -726px;
}
.f-btn.f-lg .f-icon.f-print:before,
.f-icon.f-lg.f-print:before {
  background-position-x: -858px;
}

.f-btn.f-xss .f-icon.f-envelope:before,
.f-icon.f-xss.f-envelope:before {
  background-position-x: -544px;
}
.f-icon.f-envelope:before,
.f-btn.f-xs .f-icon.f-envelope:before,
.f-icon.f-xs.f-envelope:before {
  background-position-x: -612px;
}
.f-btn.f-sm .f-icon.f-envelope:before,
.f-icon.f-sm.f-envelope:before {
  background-position-x: -680px;
}
.f-btn.f-md .f-icon.f-envelope:before,
.f-icon.f-md.f-envelope:before {
  background-position-x: -748px;
}
.f-btn.f-lg .f-icon.f-envelope:before,
.f-icon.f-lg.f-envelope:before {
  background-position-x: -884px;
}

.f-btn.f-xss .f-icon.f-paper-plane:before,
.f-icon.f-xss.f-paper-plane:before {
  background-position-x: -560px;
}
.f-icon.f-paper-plane:before,
.f-btn.f-xs .f-icon.f-paper-plane:before,
.f-icon.f-xs.f-paper-plane:before {
  background-position-x: -630px;
}
.f-btn.f-sm .f-icon.f-paper-plane:before,
.f-icon.f-sm.f-paper-plane:before {
  background-position-x: -700px;
}
.f-btn.f-md .f-icon.f-paper-plane:before,
.f-icon.f-md.f-paper-plane:before {
  background-position-x: -770px;
}
.f-btn.f-lg .f-icon.f-paper-plane:before,
.f-icon.f-lg.f-paper-plane:before {
  background-position-x: -910px;
}

.f-btn.f-xss .f-icon.f-circled-cross:before,
.f-icon.f-xss.f-circled-cross:before {
  background-position-x: -576px;
}
.f-icon.f-circled-cross:before,
.f-btn.f-xs .f-icon.f-circled-cross:before,
.f-icon.f-xs.f-circled-cross:before {
  background-position-x: -648px;
}
.f-btn.f-sm .f-icon.f-circled-cross:before,
.f-icon.f-sm.f-circled-cross:before {
  background-position-x: -720px;
}
.f-btn.f-md .f-icon.f-circled-cross:before,
.f-icon.f-md.f-circled-cross:before {
  background-position-x: -792px;
}
.f-btn.f-lg .f-icon.f-circled-cross:before,
.f-icon.f-lg.f-circled-cross:before {
  background-position-x: -936px;
}

.f-btn.f-xss .f-icon.f-bullets:before,
.f-icon.f-xss.f-bullets:before {
  background-position-x: -592px;
}
.f-icon.f-bullets:before,
.f-btn.f-xs .f-icon.f-bullets:before,
.f-icon.f-xs.f-bullets:before {
  background-position-x: -666px;
}
.f-btn.f-sm .f-icon.f-bullets:before,
.f-icon.f-sm.f-bullets:before {
  background-position-x: -740px;
}
.f-btn.f-md .f-icon.f-bullets:before,
.f-icon.f-md.f-bullets:before {
  background-position-x: -814px;
}
.f-btn.f-lg .f-icon.f-bullets:before,
.f-icon.f-lg.f-bullets:before {
  background-position-x: -962px;
}

.f-btn.f-xss .f-icon.f-ribbon-badge:before,
.f-icon.f-xss.f-ribbon-badge:before {
  background-position-x: -608px;
}
.f-icon.f-ribbon-badge:before,
.f-btn.f-xs .f-icon.f-ribbon-badge:before,
.f-icon.f-xs.f-ribbon-badge:before {
  background-position-x: -684px;
}
.f-btn.f-sm .f-icon.f-ribbon-badge:before,
.f-icon.f-sm.f-ribbon-badge:before {
  background-position-x: -760px;
}
.f-btn.f-md .f-icon.f-ribbon-badge:before,
.f-icon.f-md.f-ribbon-badge:before {
  background-position-x: -836px;
}
.f-btn.f-lg .f-icon.f-ribbon-badge:before,
.f-icon.f-lg.f-ribbon-badge:before {
  background-position-x: -988px;
}

.f-btn.f-xss .f-icon.f-wrench:before,
.f-icon.f-xss.f-wrench:before {
  background-position-x: -624px;
}
.f-icon.f-wrench:before,
.f-btn.f-xs .f-icon.f-wrench:before,
.f-icon.f-xs.f-wrench:before {
  background-position-x: -702px;
}
.f-btn.f-sm .f-icon.f-wrench:before,
.f-icon.f-sm.f-wrench:before {
  background-position-x: -780px;
}
.f-btn.f-md .f-icon.f-wrench:before,
.f-icon.f-md.f-wrench:before {
  background-position-x: -858px;
}
.f-btn.f-lg .f-icon.f-wrench:before,
.f-icon.f-lg.f-wrench:before {
  background-position-x: -1014px;
}

.f-btn.f-xss .f-icon.f-circled-question:before,
.f-icon.f-xss.f-circled-question:before {
  background-position-x: -640px;
}
.f-icon.f-circled-question:before,
.f-btn.f-xs .f-icon.f-circled-question:before,
.f-icon.f-xs.f-circled-question:before {
  background-position-x: -720px;
}
.f-btn.f-sm .f-icon.f-circled-question:before,
.f-icon.f-sm.f-circled-question:before {
  background-position-x: -800px;
}
.f-btn.f-md .f-icon.f-circled-question:before,
.f-icon.f-md.f-circled-question:before {
  background-position-x: -880px;
}
.f-btn.f-lg .f-icon.f-circled-question:before,
.f-icon.f-lg.f-circled-question:before {
  background-position-x: -1040px;
}

.f-btn.f-xss .f-icon.f-calendar-check:before,
.f-icon.f-xss.f-calendar-check:before {
  background-position-x: -656px;
}
.f-icon.f-calendar-check:before,
.f-btn.f-xs .f-icon.f-calendar-check:before,
.f-icon.f-xs.f-calendar-check:before {
  background-position-x: -738px;
}
.f-btn.f-sm .f-icon.f-calendar-check:before,
.f-icon.f-sm.f-calendar-check:before {
  background-position-x: -820px;
}
.f-btn.f-md .f-icon.f-calendar-check:before,
.f-icon.f-md.f-calendar-check:before {
  background-position-x: -902px;
}
.f-btn.f-lg .f-icon.f-calendar-check:before,
.f-icon.f-lg.f-calendar-check:before {
  background-position-x: -1066px;
}

.f-btn.f-xss .f-icon.f-megaphone:before,
.f-icon.f-xss.f-megaphone:before {
  background-position-x: -672px;
}
.f-icon.f-megaphone:before,
.f-btn.f-xs .f-icon.f-megaphone:before,
.f-icon.f-xs.f-megaphone:before {
  background-position-x: -756px;
}
.f-btn.f-sm .f-icon.f-megaphone:before,
.f-icon.f-sm.f-megaphone:before {
  background-position-x: -840px;
}
.f-btn.f-md .f-icon.f-megaphone:before,
.f-icon.f-md.f-megaphone:before {
  background-position-x: -924px;
}
.f-btn.f-lg .f-icon.f-megaphone:before,
.f-icon.f-lg.f-megaphone:before {
  background-position-x: -1092px;
}

.f-btn.f-xss .f-icon.f-display:before,
.f-icon.f-xss.f-display:before {
  background-position-x: -688px;
}
.f-icon.f-display:before,
.f-btn.f-xs .f-icon.f-display:before,
.f-icon.f-xs.f-display:before {
  background-position-x: -774px;
}
.f-btn.f-sm .f-icon.f-display:before,
.f-icon.f-sm.f-display:before {
  background-position-x: -860px;
}
.f-btn.f-md .f-icon.f-display:before,
.f-icon.f-md.f-display:before {
  background-position-x: -946px;
}
.f-btn.f-lg .f-icon.f-display:before,
.f-icon.f-lg.f-display:before {
  background-position-x: -1118px;
}

.f-btn.f-xss .f-icon.f-tag:before,
.f-icon.f-xss.f-tag:before {
  background-position-x: -704px;
}
.f-icon.f-tag:before,
.f-btn.f-xs .f-icon.f-tag:before,
.f-icon.f-xs.f-tag:before {
  background-position-x: -792px;
}
.f-btn.f-sm .f-icon.f-tag:before,
.f-icon.f-sm.f-tag:before {
  background-position-x: -880px;
}
.f-btn.f-md .f-icon.f-tag:before,
.f-icon.f-md.f-tag:before {
  background-position-x: -968px;
}
.f-btn.f-lg .f-icon.f-tag:before,
.f-icon.f-lg.f-tag:before {
  background-position-x: -1144px;
}

.f-btn.f-xss .f-icon.f-document:before,
.f-icon.f-xss.f-document:before {
  background-position-x: -720px;
}
.f-icon.f-document:before,
.f-btn.f-xs .f-icon.f-document:before,
.f-icon.f-xs.f-document:before {
  background-position-x: -810px;
}
.f-btn.f-sm .f-icon.f-document:before,
.f-icon.f-sm.f-document:before {
  background-position-x: -900px;
}
.f-btn.f-md .f-icon.f-document:before,
.f-icon.f-md.f-document:before {
  background-position-x: -990px;
}
.f-btn.f-lg .f-icon.f-document:before,
.f-icon.f-lg.f-document:before {
  background-position-x: -1170px;
}

.f-btn.f-xss .f-icon.f-document-files:before,
.f-icon.f-xss.f-document-files:before {
  background-position-x: -736px;
}
.f-icon.f-document-files:before,
.f-btn.f-xs .f-icon.f-document-files:before,
.f-icon.f-xs.f-document-files:before {
  background-position-x: -828px;
}
.f-btn.f-sm .f-icon.f-document-files:before,
.f-icon.f-sm.f-document-files:before {
  background-position-x: -920px;
}
.f-btn.f-md .f-icon.f-document-files:before,
.f-icon.f-md.f-document-files:before {
  background-position-x: -1012px;
}
.f-btn.f-lg .f-icon.f-document-files:before,
.f-icon.f-lg.f-document-files:before {
  background-position-x: -1196px;
}

.f-btn.f-xss .f-icon.f-pin-place:before,
.f-icon.f-xss.f-pin-place:before {
  background-position-x: -752px;
}
.f-icon.f-pin-place:before,
.f-btn.f-xs .f-icon.f-pin-place:before,
.f-icon.f-xs.f-pin-place:before {
  background-position-x: -846px;
}
.f-btn.f-sm .f-icon.f-pin-place:before,
.f-icon.f-sm.f-pin-place:before {
  background-position-x: -940px;
}
.f-btn.f-md .f-icon.f-pin-place:before,
.f-icon.f-md.f-pin-place:before {
  background-position-x: -1034px;
}
.f-btn.f-lg .f-icon.f-pin-place:before,
.f-icon.f-lg.f-pin-place:before {
  background-position-x: -1222px;
}

.f-btn.f-xss .f-icon.f-paint-brush:before,
.f-icon.f-xss.f-paint-brush:before {
  background-position-x: -768px;
}
.f-icon.f-paint-brush:before,
.f-btn.f-xs .f-icon.f-paint-brush:before,
.f-icon.f-xs.f-paint-brush:before {
  background-position-x: -864px;
}
.f-btn.f-sm .f-icon.f-paint-brush:before,
.f-icon.f-sm.f-paint-brush:before {
  background-position-x: -960px;
}
.f-btn.f-md .f-icon.f-paint-brush:before,
.f-icon.f-md.f-paint-brush:before {
  background-position-x: -1056px;
}
.f-btn.f-lg .f-icon.f-paint-brush:before,
.f-icon.f-lg.f-paint-brush:before {
  background-position-x: -1248px;
}

.f-btn.f-xss .f-icon.f-gear:before,
.f-icon.f-xss.f-gear:before {
  background-position-x: -784px;
}
.f-icon.f-gear:before,
.f-btn.f-xs .f-icon.f-gear:before,
.f-icon.f-xs.f-gear:before {
  background-position-x: -882px;
}
.f-btn.f-sm .f-icon.f-gear:before,
.f-icon.f-sm.f-gear:before {
  background-position-x: -980px;
}
.f-btn.f-md .f-icon.f-gear:before,
.f-icon.f-md.f-gear:before {
  background-position-x: -1078px;
}
.f-btn.f-lg .f-icon.f-gear:before,
.f-icon.f-lg.f-gear:before {
  background-position-x: -1274px;
}

.f-btn.f-xss .f-icon.f-check-options:before,
.f-icon.f-xss.f-check-options:before {
  background-position-x: -800px;
}
.f-icon.f-check-options:before,
.f-btn.f-xs .f-icon.f-check-options:before,
.f-icon.f-xs.f-check-options:before {
  background-position-x: -900px;
}
.f-btn.f-sm .f-icon.f-check-options:before,
.f-icon.f-sm.f-check-options:before {
  background-position-x: -1000px;
}
.f-btn.f-md .f-icon.f-check-options:before,
.f-icon.f-md.f-check-options:before {
  background-position-x: -1100px;
}
.f-btn.f-lg .f-icon.f-check-options:before,
.f-icon.f-lg.f-check-options:before {
  background-position-x: -1300px;
}

.f-btn.f-xss .f-icon.f-hashtag-doc:before,
.f-icon.f-xss.f-hashtag-doc:before {
  background-position-x: -816px;
}
.f-icon.f-hashtag-doc:before,
.f-btn.f-xs .f-icon.f-hashtag-doc:before,
.f-icon.f-xs.f-hashtag-doc:before {
  background-position-x: -918px;
}
.f-btn.f-sm .f-icon.f-hashtag-doc:before,
.f-icon.f-sm.f-hashtag-doc:before {
  background-position-x: -1020px;
}
.f-btn.f-md .f-icon.f-hashtag-doc:before,
.f-icon.f-md.f-hashtag-doc:before {
  background-position-x: -1122px;
}
.f-btn.f-lg .f-icon.f-hashtag-doc:before,
.f-icon.f-lg.f-hashtag-doc:before {
  background-position-x: -1326px;
}

.f-btn.f-xss .f-icon.f-hashtag-target:before,
.f-icon.f-xss.f-hashtag-target:before {
  background-position-x: -832px;
}
.f-icon.f-hashtag-target:before,
.f-btn.f-xs .f-icon.f-hashtag-target:before,
.f-icon.f-xs.f-hashtag-target:before {
  background-position-x: -936px;
}
.f-btn.f-sm .f-icon.f-hashtag-target:before,
.f-icon.f-sm.f-hashtag-target:before {
  background-position-x: -1040px;
}
.f-btn.f-md .f-icon.f-hashtag-target:before,
.f-icon.f-md.f-hashtag-target:before {
  background-position-x: -1144px;
}
.f-btn.f-lg .f-icon.f-hashtag-target:before,
.f-icon.f-lg.f-hashtag-target:before {
  background-position-x: -1352px;
}

.f-btn.f-xss .f-icon.f-eye:before,
.f-icon.f-xss.f-eye:before {
  background-position-x: -848px;
}
.f-icon.f-eye:before,
.f-btn.f-xs .f-icon.f-eye:before,
.f-icon.f-xs.f-eye:before {
  background-position-x: -954px;
}
.f-btn.f-sm .f-icon.f-eye:before,
.f-icon.f-sm.f-eye:before {
  background-position-x: -1060px;
}
.f-btn.f-md .f-icon.f-eye:before,
.f-icon.f-md.f-eye:before {
  background-position-x: -1166px;
}
.f-btn.f-lg .f-icon.f-eye:before,
.f-icon.f-lg.f-eye:before {
  background-position-x: -1378px;
}

.f-btn.f-xss .f-icon.f-analysis:before,
.f-icon.f-xss.f-analysis:before {
  background-position-x: -864px;
}
.f-icon.f-analysis:before,
.f-btn.f-xs .f-icon.f-analysis:before,
.f-icon.f-xs.f-analysis:before {
  background-position-x: -972px;
}
.f-btn.f-sm .f-icon.f-analysis:before,
.f-icon.f-sm.f-analysis:before {
  background-position-x: -1080px;
}
.f-btn.f-md .f-icon.f-analysis:before,
.f-icon.f-md.f-analysis:before {
  background-position-x: -1188px;
}
.f-btn.f-lg .f-icon.f-analysis:before,
.f-icon.f-lg.f-analysis:before {
  background-position-x: -1404px;
}

.f-btn.f-xss .f-icon.f-shop-store:before,
.f-icon.f-xss.f-shop-store:before {
  background-position-x: -880px;
}
.f-icon.f-shop-store:before,
.f-btn.f-xs .f-icon.f-shop-store:before,
.f-icon.f-xs.f-shop-store:before {
  background-position-x: -990px;
}
.f-btn.f-sm .f-icon.f-shop-store:before,
.f-icon.f-sm.f-shop-store:before {
  background-position-x: -1100px;
}
.f-btn.f-md .f-icon.f-shop-store:before,
.f-icon.f-md.f-shop-store:before {
  background-position-x: -1210px;
}
.f-btn.f-lg .f-icon.f-shop-store:before,
.f-icon.f-lg.f-shop-store:before {
  background-position-x: -1430px;
}

.f-btn.f-xss .f-icon.f-gear-wrench:before,
.f-icon.f-xss.f-gear-wrench:before {
  background-position-x: -896px;
}
.f-icon.f-gear-wrench:before,
.f-btn.f-xs .f-icon.f-gear-wrench:before,
.f-icon.f-xs.f-gear-wrench:before {
  background-position-x: -1008px;
}
.f-btn.f-sm .f-icon.f-gear-wrench:before,
.f-icon.f-sm.f-gear-wrench:before {
  background-position-x: -1120px;
}
.f-btn.f-md .f-icon.f-gear-wrench:before,
.f-icon.f-md.f-gear-wrench:before {
  background-position-x: -1232px;
}
.f-btn.f-lg .f-icon.f-gear-wrench:before,
.f-icon.f-lg.f-gear-wrench:before {
  background-position-x: -1456px;
}

.f-btn.f-xss .f-icon.f-exit:before,
.f-icon.f-xss.f-exit:before {
  background-position-x: -912px;
}
.f-icon.f-exit:before,
.f-btn.f-xs .f-icon.f-exit:before,
.f-icon.f-xs.f-exit:before {
  background-position-x: -1026px;
}
.f-btn.f-sm .f-icon.f-exit:before,
.f-icon.f-sm.f-exit:before {
  background-position-x: -1140px;
}
.f-btn.f-md .f-icon.f-exit:before,
.f-icon.f-md.f-exit:before {
  background-position-x: -1254px;
}
.f-btn.f-lg .f-icon.f-exit:before,
.f-icon.f-lg.f-exit:before {
  background-position-x: -1482px;
}

.f-btn.f-xss .f-icon.f-credit-details:before,
.f-icon.f-xss.f-credit-details:before {
  background-position-x: -928px;
}
.f-icon.f-credit-details:before,
.f-btn.f-xs .f-icon.f-credit-details:before,
.f-icon.f-xs.f-credit-details:before {
  background-position-x: -1044px;
}
.f-btn.f-sm .f-icon.f-credit-details:before,
.f-icon.f-sm.f-credit-details:before {
  background-position-x: -1160px;
}
.f-btn.f-md .f-icon.f-credit-details:before,
.f-icon.f-md.f-credit-details:before {
  background-position-x: -1276px;
}
.f-btn.f-lg .f-icon.f-credit-details:before,
.f-icon.f-lg.f-credit-details:before {
  background-position-x: -1508px;
}

.f-btn.f-xss .f-icon.f-recent-payments:before,
.f-icon.f-xss.f-recent-payments:before {
  background-position-x: -944px;
}
.f-icon.f-recent-payments:before,
.f-btn.f-xs .f-icon.f-recent-payments:before,
.f-icon.f-xs.f-recent-payments:before {
  background-position-x: -1062px;
}
.f-btn.f-sm .f-icon.f-recent-payments:before,
.f-icon.f-sm.f-recent-payments:before {
  background-position-x: -1180px;
}
.f-btn.f-md .f-icon.f-recent-payments:before,
.f-icon.f-md.f-recent-payments:before {
  background-position-x: -1298px;
}
.f-btn.f-lg .f-icon.f-recent-payments:before,
.f-icon.f-lg.f-recent-payments:before {
  background-position-x: -1534px;
}

.f-btn.f-xss .f-icon.f-finance-alerts:before,
.f-icon.f-xss.f-finance-alerts:before {
  background-position-x: -960px;
}
.f-icon.f-finance-alerts:before,
.f-btn.f-xs .f-icon.f-finance-alerts:before,
.f-icon.f-xs.f-finance-alerts:before {
  background-position-x: -1080px;
}
.f-btn.f-sm .f-icon.f-finance-alerts:before,
.f-icon.f-sm.f-finance-alerts:before {
  background-position-x: -1200px;
}
.f-btn.f-md .f-icon.f-finance-alerts:before,
.f-icon.f-md.f-finance-alerts:before {
  background-position-x: -1320px;
}
.f-btn.f-lg .f-icon.f-finance-alerts:before,
.f-icon.f-lg.f-finance-alerts:before {
  background-position-x: -1560px;
}

.f-btn.f-xss .f-icon.f-payment-accounts:before,
.f-icon.f-xss.f-payment-accounts:before {
  background-position-x: -976px;
}
.f-icon.f-payment-accounts:before,
.f-btn.f-xs .f-icon.f-payment-accounts:before,
.f-icon.f-xs.f-payment-accounts:before {
  background-position-x: -1098px;
}
.f-btn.f-sm .f-icon.f-payment-accounts:before,
.f-icon.f-sm.f-payment-accounts:before {
  background-position-x: -1220px;
}
.f-btn.f-md .f-icon.f-payment-accounts:before,
.f-icon.f-md.f-payment-accounts:before {
  background-position-x: -1342px;
}
.f-btn.f-lg .f-icon.f-payment-accounts:before,
.f-icon.f-lg.f-payment-accounts:before {
  background-position-x: -1586px;
}

.f-btn.f-xss .f-icon.f-filters:before,
.f-icon.f-xss.f-filters:before {
  background-position-x: -992px;
}
.f-icon.f-filters:before,
.f-btn.f-xs .f-icon.f-filters:before,
.f-icon.f-xs.f-filters:before {
  background-position-x: -1116px;
}
.f-btn.f-sm .f-icon.f-filters:before,
.f-icon.f-sm.f-filters:before {
  background-position-x: -1240px;
}
.f-btn.f-md .f-icon.f-filters:before,
.f-icon.f-md.f-filters:before {
  background-position-x: -1364px;
}
.f-btn.f-lg .f-icon.f-filters:before,
.f-icon.f-lg.f-filters:before {
  background-position-x: -1612px;
}

.f-btn.f-xss .f-icon.f-recent-shipments:before,
.f-icon.f-xss.f-recent-shipments:before {
  background-position-x: -1008px;
}
.f-icon.f-recent-shipments:before,
.f-btn.f-xs .f-icon.f-recent-shipments:before,
.f-icon.f-xs.f-recent-shipments:before {
  background-position-x: -1134px;
}
.f-btn.f-sm .f-icon.f-recent-shipments:before,
.f-icon.f-sm.f-recent-shipments:before {
  background-position-x: -1260px;
}
.f-btn.f-md .f-icon.f-recent-shipments:before,
.f-icon.f-md.f-recent-shipments:before {
  background-position-x: -1386px;
}
.f-btn.f-lg .f-icon.f-recent-shipments:before,
.f-icon.f-lg.f-recent-shipments:before {
  background-position-x: -1638px;
}

.f-btn.f-xss .f-icon.f-orders:before,
.f-icon.f-xss.f-orders:before {
  background-position-x: -1024px;
}
.f-icon.f-orders:before,
.f-btn.f-xs .f-icon.f-orders:before,
.f-icon.f-xs.f-orders:before {
  background-position-x: -1152px;
}
.f-btn.f-sm .f-icon.f-orders:before,
.f-icon.f-sm.f-orders:before {
  background-position-x: -1280px;
}
.f-btn.f-md .f-icon.f-orders:before,
.f-icon.f-md.f-orders:before {
  background-position-x: -1408px;
}
.f-btn.f-lg .f-icon.f-orders:before,
.f-icon.f-lg.f-orders:before {
  background-position-x: -1664px;
}

.f-btn.f-xss .f-icon.f-finance:before,
.f-icon.f-xss.f-finance:before {
  background-position-x: -1040px;
}
.f-icon.f-finance:before,
.f-btn.f-xs .f-icon.f-finance:before,
.f-icon.f-xs.f-finance:before {
  background-position-x: -1170px;
}
.f-btn.f-sm .f-icon.f-finance:before,
.f-icon.f-sm.f-finance:before {
  background-position-x: -1300px;
}
.f-btn.f-md .f-icon.f-finance:before,
.f-icon.f-md.f-finance:before {
  background-position-x: -1430px;
}
.f-btn.f-lg .f-icon.f-finance:before,
.f-icon.f-lg.f-finance:before {
  background-position-x: -1690px;
}

.f-btn.f-xss .f-icon.f-aging-information:before,
.f-icon.f-xss.f-aging-information:before {
  background-position-x: -1056px;
}
.f-icon.f-aging-information:before,
.f-btn.f-xs .f-icon.f-aging-information:before,
.f-icon.f-xs.f-aging-information:before {
  background-position-x: -1188px;
}
.f-btn.f-sm .f-icon.f-aging-information:before,
.f-icon.f-sm.f-aging-information:before {
  background-position-x: -1320px;
}
.f-btn.f-md .f-icon.f-aging-information:before,
.f-icon.f-md.f-aging-information:before {
  background-position-x: -1452px;
}
.f-btn.f-lg .f-icon.f-aging-information:before,
.f-icon.f-lg.f-aging-information:before {
  background-position-x: -1716px;
}

.f-btn.f-xss .f-icon.f-three-dots-circle:before,
.f-icon.f-xss.f-three-dots-circle:before {
  background-position-x: -1072px;
}
.f-icon.f-three-dots-circle:before,
.f-btn.f-xs .f-icon.f-three-dots-circle:before,
.f-icon.f-xs.f-three-dots-circle:before {
  background-position-x: -1206px;
}
.f-btn.f-sm .f-icon.f-three-dots-circle:before,
.f-icon.f-sm.f-three-dots-circle:before {
  background-position-x: -1340px;
}
.f-btn.f-md .f-icon.f-three-dots-circle:before,
.f-icon.f-md.f-three-dots-circle:before {
  background-position-x: -1474px;
}
.f-btn.f-lg .f-icon.f-three-dots-circle:before,
.f-icon.f-lg.f-three-dots-circle:before {
  background-position-x: -1742px;
}

.f-btn.f-xss .f-icon.f-folder:before,
.f-icon.f-xss.f-folder:before {
  background-position-x: -1088px;
}
.f-icon.f-folder:before,
.f-btn.f-xs .f-icon.f-folder:before,
.f-icon.f-xs.f-folder:before {
  background-position-x: -1224px;
}
.f-btn.f-sm .f-icon.f-folder:before,
.f-icon.f-sm.f-folder:before {
  background-position-x: -1360px;
}
.f-btn.f-md .f-icon.f-folder:before,
.f-icon.f-md.f-folder:before {
  background-position-x: -1496px;
}
.f-btn.f-lg .f-icon.f-folder:before,
.f-icon.f-lg.f-folder:before {
  background-position-x: -1768px;
}

.f-btn.f-xss .f-icon.f-house:before,
.f-icon.f-xss.f-house:before {
  background-position-x: -1104px;
}
.f-icon.f-house:before,
.f-btn.f-xs .f-icon.f-house:before,
.f-icon.f-xs.f-house:before {
  background-position-x: -1242px;
}
.f-btn.f-sm .f-icon.f-house:before,
.f-icon.f-sm.f-house:before {
  background-position-x: -1380px;
}
.f-btn.f-md .f-icon.f-house:before,
.f-icon.f-md.f-house:before {
  background-position-x: -1518px;
}
.f-btn.f-lg .f-icon.f-house:before,
.f-icon.f-lg.f-house:before {
  background-position-x: -1794px;
}

.f-btn.f-xss .f-icon.f-plus:before,
.f-icon.f-xss.f-plus:before {
  background-position-x: -1120px;
}
.f-icon.f-plus:before,
.f-btn.f-xs .f-icon.f-plus:before,
.f-icon.f-xs.f-plus:before {
  background-position-x: -1260px;
}
.f-btn.f-sm .f-icon.f-plus:before,
.f-icon.f-sm.f-plus:before {
  background-position-x: -1400px;
}
.f-btn.f-md .f-icon.f-plus:before,
.f-icon.f-md.f-plus:before {
  background-position-x: -1540px;
}
.f-btn.f-lg .f-icon.f-plus:before,
.f-icon.f-lg.f-plus:before {
  background-position-x: -1820px;
}

.f-btn.f-xss .f-icon.f-minus:before,
.f-icon.f-xss.f-minus:before {
  background-position-x: -1136px;
}
.f-icon.f-minus:before,
.f-btn.f-xs .f-icon.f-minus:before,
.f-icon.f-xs.f-minus:before {
  background-position-x: -1278px;
}
.f-btn.f-sm .f-icon.f-minus:before,
.f-icon.f-sm.f-minus:before {
  background-position-x: -1420px;
}
.f-btn.f-md .f-icon.f-minus:before,
.f-icon.f-md.f-minus:before {
  background-position-x: -1562px;
}
.f-btn.f-lg .f-icon.f-minus:before,
.f-icon.f-lg.f-minus:before {
  background-position-x: -1846px;
}

.f-btn.f-xss .f-icon.f-top-items:before,
.f-icon.f-xss.f-top-items:before {
  background-position-x: -1152px;
}
.f-icon.f-top-items:before,
.f-btn.f-xs .f-icon.f-top-items:before,
.f-icon.f-xs.f-top-items:before {
  background-position-x: -1296px;
}
.f-btn.f-sm .f-icon.f-top-items:before,
.f-icon.f-sm.f-top-items:before {
  background-position-x: -1440px;
}
.f-btn.f-md .f-icon.f-top-items:before,
.f-icon.f-md.f-top-items:before {
  background-position-x: -1584px;
}
.f-btn.f-lg .f-icon.f-top-items:before,
.f-icon.f-lg.f-top-items:before {
  background-position-x: -1872px;
}

.f-btn.f-xss .f-icon.f-next-drawing:before,
.f-icon.f-xss.f-next-drawing:before {
  background-position-x: -1168px;
}
.f-icon.f-next-drawing:before,
.f-btn.f-xs .f-icon.f-next-drawing:before,
.f-icon.f-xs.f-next-drawing:before {
  background-position-x: -1314px;
}
.f-btn.f-sm .f-icon.f-next-drawing:before,
.f-icon.f-sm.f-next-drawing:before {
  background-position-x: -1460px;
}
.f-btn.f-md .f-icon.f-next-drawing:before,
.f-icon.f-md.f-next-drawing:before {
  background-position-x: -1606px;
}
.f-btn.f-lg .f-icon.f-next-drawing:before,
.f-icon.f-lg.f-next-drawing:before {
  background-position-x: -1898px;
}

.f-btn.f-xss .f-icon.f-my-summary:before,
.f-icon.f-xss.f-my-summary:before {
  background-position-x: -1184px;
}
.f-icon.f-my-summary:before,
.f-btn.f-xs .f-icon.f-my-summary:before,
.f-icon.f-xs.f-my-summary:before {
  background-position-x: -1332px;
}
.f-btn.f-sm .f-icon.f-my-summary:before,
.f-icon.f-sm.f-my-summary:before {
  background-position-x: -1480px;
}
.f-btn.f-md .f-icon.f-my-summary:before,
.f-icon.f-md.f-my-summary:before {
  background-position-x: -1628px;
}
.f-btn.f-lg .f-icon.f-my-summary:before,
.f-icon.f-lg.f-my-summary:before {
  background-position-x: -1924px;
}

.f-btn.f-xss .f-icon.f-bulletin-board:before,
.f-icon.f-xss.f-bulletin-board:before {
  background-position-x: -1200px;
}
.f-icon.f-bulletin-board:before,
.f-btn.f-xs .f-icon.f-bulletin-board:before,
.f-icon.f-xs.f-bulletin-board:before {
  background-position-x: -1350px;
}
.f-btn.f-sm .f-icon.f-bulletin-board:before,
.f-icon.f-sm.f-bulletin-board:before {
  background-position-x: -1500px;
}
.f-btn.f-md .f-icon.f-bulletin-board:before,
.f-icon.f-md.f-bulletin-board:before {
  background-position-x: -1650px;
}
.f-btn.f-lg .f-icon.f-bulletin-board:before,
.f-icon.f-lg.f-bulletin-board:before {
  background-position-x: -1950px;
}

.f-btn.f-xss .f-icon.f-service-docs:before,
.f-icon.f-xss.f-service-docs:before {
  background-position-x: -1216px;
}
.f-icon.f-service-docs:before,
.f-btn.f-xs .f-icon.f-service-docs:before,
.f-icon.f-xs.f-service-docs:before {
  background-position-x: -1368px;
}
.f-btn.f-sm .f-icon.f-service-docs:before,
.f-icon.f-sm.f-service-docs:before {
  background-position-x: -1520px;
}
.f-btn.f-md .f-icon.f-service-docs:before,
.f-icon.f-md.f-service-docs:before {
  background-position-x: -1672px;
}
.f-btn.f-lg .f-icon.f-service-docs:before,
.f-icon.f-lg.f-service-docs:before {
  background-position-x: -1976px;
}

.f-btn.f-xss .f-icon.f-auth-service-centers:before,
.f-icon.f-xss.f-auth-service-centers:before {
  background-position-x: -1232px;
}
.f-icon.f-auth-service-centers:before,
.f-btn.f-xs .f-icon.f-auth-service-centers:before,
.f-icon.f-xs.f-auth-service-centers:before {
  background-position-x: -1386px;
}
.f-btn.f-sm .f-icon.f-auth-service-centers:before,
.f-icon.f-sm.f-auth-service-centers:before {
  background-position-x: -1540px;
}
.f-btn.f-md .f-icon.f-auth-service-centers:before,
.f-icon.f-md.f-auth-service-centers:before {
  background-position-x: -1694px;
}
.f-btn.f-lg .f-icon.f-auth-service-centers:before,
.f-icon.f-lg.f-auth-service-centers:before {
  background-position-x: -2002px;
}

.f-btn.f-xss .f-icon.f-videos:before,
.f-icon.f-xss.f-videos:before {
  background-position-x: -1248px;
}
.f-icon.f-videos:before,
.f-btn.f-xs .f-icon.f-videos:before,
.f-icon.f-xs.f-videos:before {
  background-position-x: -1404px;
}
.f-btn.f-sm .f-icon.f-videos:before,
.f-icon.f-sm.f-videos:before {
  background-position-x: -1560px;
}
.f-btn.f-md .f-icon.f-videos:before,
.f-icon.f-md.f-videos:before {
  background-position-x: -1716px;
}
.f-btn.f-lg .f-icon.f-videos:before,
.f-icon.f-lg.f-videos:before {
  background-position-x: -2028px;
}

.f-btn.f-xss .f-icon.f-view-claims:before,
.f-icon.f-xss.f-view-claims:before {
  background-position-x: -1264px;
}
.f-icon.f-view-claims:before,
.f-btn.f-xs .f-icon.f-view-claims:before,
.f-icon.f-xs.f-view-claims:before {
  background-position-x: -1422px;
}
.f-btn.f-sm .f-icon.f-view-claims:before,
.f-icon.f-sm.f-view-claims:before {
  background-position-x: -1580px;
}
.f-btn.f-md .f-icon.f-view-claims:before,
.f-icon.f-md.f-view-claims:before {
  background-position-x: -1738px;
}
.f-btn.f-lg .f-icon.f-view-claims:before,
.f-icon.f-lg.f-view-claims:before {
  background-position-x: -2054px;
}

.f-btn.f-xss .f-icon.f-parts-request:before,
.f-icon.f-xss.f-parts-request:before {
  background-position-x: -1280px;
}
.f-icon.f-parts-request:before,
.f-btn.f-xs .f-icon.f-parts-request:before,
.f-icon.f-xs.f-parts-request:before {
  background-position-x: -1440px;
}
.f-btn.f-sm .f-icon.f-parts-request:before,
.f-icon.f-sm.f-parts-request:before {
  background-position-x: -1600px;
}
.f-btn.f-md .f-icon.f-parts-request:before,
.f-icon.f-md.f-parts-request:before {
  background-position-x: -1760px;
}
.f-btn.f-lg .f-icon.f-parts-request:before,
.f-icon.f-lg.f-parts-request:before {
  background-position-x: -2080px;
}

.f-btn.f-xss .f-icon.f-copy:before,
.f-icon.f-xss.f-copy:before {
  background-position-x: -1312px;
}
.f-icon.f-copy:before,
.f-btn.f-xs .f-icon.f-copy:before,
.f-icon.f-xs.f-copy:before {
  background-position-x: -1476px;
}
.f-btn.f-sm .f-icon.f-copy:before,
.f-icon.f-sm.f-copy:before {
  background-position-x: -1640px;
}
.f-btn.f-md .f-icon.f-copy:before,
.f-icon.f-md.f-copy:before {
  background-position-x: -1804px;
}
.f-btn.f-lg .f-icon.f-copy:before,
.f-icon.f-lg.f-copy:before {
  background-position-x: -2132px;
}

.f-btn.f-xss .f-icon.f-copy-clipboard:before,
.f-icon.f-xss.f-copy-clipboard:before {
  background-position-x: -1344px;
}

.f-icon.f-copy-clipboard:before,
.f-btn.f-xs .f-icon.f-copy-clipboard:before,
.f-icon.f-xs.f-copy-clipboard:before {
  background-position-x: -1512px;
}

.f-btn.f-sm .f-icon.f-copy-clipboard:before,
.f-icon.f-sm.f-copy-clipboard:before {
  background-position-x: -1680px;
}

.f-btn.f-md .f-copy-clipboard:before,
.f-icon.f-md.f-copy-clipboard:before {
  background-position-x: -1848px;
}

.f-btn.f-lg .f-icon.f-copy-clipboard:before,
.f-icon.f-lg.f-copy-clipboard:before {
  background-position-x: -2184px;
}

/* finance icon */
.f-btn.f-xss .f-icon.f-finance-bank:before,
.f-icon.f-xss.f-finance-bank:before {
  background-position-x: -1360px;
}

.f-icon.f-finance-bank:before,
.f-btn.f-xs .f-icon.f-finance-bank:before,
.f-icon.f-xs.f-finance-bank:before {
  background-position-x: -1530px;
}

.f-btn.f-sm .f-icon.f-finance-bank:before,
.f-icon.f-sm.f-finance-bank:before {
  background-position-x: -1700px;
}

.f-btn.f-md .f-finance-bank:before,
.f-icon.f-md.f-finance-bank:before {
  background-position-x: -1870px;
}

.f-btn.f-lg .f-icon.f-finance-bank:before,
.f-icon.f-lg.f-finance-bank:before {
  background-position-x: -2210px;
}

/* certificates icon */
.f-btn.f-xss .f-icon.f-center-certificates:before,
.f-icon.f-xss.f-center-certificates:before {
  background-position-x: -1376px;
}

.f-icon.f-center-certificates:before,
.f-btn.f-xs .f-icon.f-center-certificates:before,
.f-icon.f-xs.f-center-certificates:before {
  background-position-x: -1548px;
}

.f-btn.f-sm .f-icon.f-center-certificates:before,
.f-icon.f-sm.f-center-certificates:before {
  background-position-x: -1720px;
}

.f-btn.f-md .f-center-certificates:before,
.f-icon.f-md.f-center-certificates:before {
  background-position-x: -1892px;
}

.f-btn.f-lg .f-icon.f-center-certificates:before,
.f-icon.f-lg.f-center-certificates:before {
  background-position-x: -2236px;
}

/* faq-trainig icon */
.f-btn.f-xss .f-icon.f-faq-training:before,
.f-icon.f-xss.f-faq-training:before {
  background-position-x: -1328px;
}

.f-icon.f-faq-training:before,
.f-btn.f-xs .f-icon.f-faq-training:before,
.f-icon.f-xs.f-faq-training:before {
  background-position-x: -1494px;
}

.f-btn.f-sm .f-icon.f-faq-training:before,
.f-icon.f-sm.f-faq-training:before {
  background-position-x: -1660px;
}

.f-btn.f-md .f-faq-training:before,
.f-icon.f-md.f-faq-training:before {
  background-position-x: -1826px;
}

.f-btn.f-lg .f-icon.f-faq-training:before,
.f-icon.f-lg.f-faq-training:before {
  background-position-x: -2158px;
}

/* share icon */
.f-btn.f-xss .f-icon.f-share:before,
.f-icon.f-xss.f-share:before {
  background-position-x: -1424px;
  background-position-y: -16px;
}

.f-icon.f-share:before,
.f-btn.f-xs .f-icon.f-share:before,
.f-icon.f-xs.f-share:before {
  background-position-x: -1602px;
}

.f-btn.f-sm .f-icon.f-share:before,
.f-icon.f-sm.f-share:before {
  background-position-x: -1780px;
}

.f-btn.f-md .f-share:before,
.f-icon.f-md.f-share:before {
  background-position-x: -1958px;
}

.f-btn.f-lg .f-icon.f-share:before,
.f-icon.f-lg.f-share:before {
  background-position-x: -2314px;
}

/* purchase-order-icon icon */
.f-btn.f-xss .f-icon.f-purchase-order-icon:before,
.f-icon.f-xss.f-purchase-order-icon:before {
  background-position-x: -1450px;
}

.f-icon.f-purchase-order-icon:before,
.f-btn.f-xs .f-icon.f-purchase-order-icon:before,
.f-icon.f-xs.f-purchase-order-icon:before {
  background-position-x: -1620px;
}

.f-btn.f-sm .f-icon.f-purchase-order-icon:before,
.f-icon.f-sm.f-purchase-order-icon:before {
  background-position-x: -1800px;
}

.f-btn.f-md .f-purchase-order-icon:before,
.f-icon.f-md.f-purchase-order-icon:before {
  background-position-x: -1980px;
}

.f-btn.f-lg .f-icon.f-purchase-order-icon:before,
.f-icon.f-lg.f-purchase-order-icon:before {
  background-position-x: -2340px;
}

/* graduation-cap icon */
.f-btn.f-xss .f-icon.f-graduation-cap:before,
.f-icon.f-xss.f-graduation-cap:before {
  background-position-x: -1514px;
}

.f-icon.f-graduation-cap:before,
.f-btn.f-xs .f-icon.f-graduation-cap:before,
.f-icon.f-xs.f-graduation-cap:before {
  background-position-x: -1656px;
}

.f-btn.f-sm .f-icon.f-graduation-cap:before,
.f-icon.f-sm.f-graduation-cap:before {
  background-position-x: -1840px;
}

.f-btn.f-md .f-graduation-cap:before,
.f-icon.f-md.f-graduation-cap:before {
  background-position-x: -2024px;
}

.f-btn.f-lg .f-icon.f-graduation-cap:before,
.f-icon.f-lg.f-graduation-cap:before {
  background-position-x: -2392px;
}

/* WIDGETS */

.f-widget,
.f-widget h1 .f-widget h2 .f-widget h3 .f-widget h4 .f-widget h5 {
  font-family: "FuturaPT-Book";
  font-size: 16px;
}
.f-widget article header h2 a,
.f-widget article header h2 label.widget-title {
  font-family: "FuturaPT-Book";
  font-size: 16px;
}

.f-widget .in-widget {
  padding: 16px;
  min-height: 337px;
}

.f-widget a {
  color: #336699;
  text-decoration: none;
  /* white-space: nowrap; */
}
.f-widget a:hover {
  text-decoration: underline;
}

/* @media(max-width: 479px) {
    .f-widget a {
        white-space: normal;
    }
} */

/* FMIC Tables */

table.f-table {
  position: relative;
  background-color: #fff;
  border: solid 1px #bbb;
  width: 100%;
  border-collapse: separate;
  border-radius: 3px;
}
.no-padding > table.f-table {
  border: solid 1px transparent;
}

table.f-table thead {
  border-bottom: 1px solid #bbb;
}
table.f-table thead tr,
table.f-table.striped thead tr {
  border: none;
}
table.f-table thead tr.tab-header th {
  padding-left: 16px;
}

table.f-table tbody tr:nth-child(odd),
table.f-table.striped tbody tr:nth-child(odd) {
  background-color: #eeeeee;
}
table.f-table tbody tr:nth-child(even),
table.f-table.striped tbody tr:nth-child(even) {
  background-color: #ffffff;
}

table.f-table thead tr:last-child th {
  border-bottom: solid 1px #bbb;
}

table.f-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 3px;
}
table.f-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 3px;
}

table.f-table tr th {
  white-space: nowrap;
  font-size: 16px;
}
table.f-table tr th,
table.f-table tr td {
  font-size: 16px;
  font-weight: normal;
  padding: 5px;
  overflow: initial;
  text-overflow: ellipsis;
  max-width: 500px;
  color: #000;
}
table.f-table tr:first-child th {
  padding-top: 6px;
  padding-bottom: 0;
}

table.f-table td[data-th]:before {
  content: attr(data-th) ": ";
  /* font-family: 'FuturaPT-Heavy'; */
  width: 40%;
  display: inline-block;
  color: #000;
  font-weight: initial;
}

@media (min-width: 1200px) {
  table.f-table td[data-th]:before {
    display: none;
  }
  table.f-table tr th:first-child,
  table.f-table tr td:first-child {
    padding-left: 16px;
  }
}

table.f-table tr th:last-child,
table.f-table tr td:last-child {
  padding-right: 16px;
}
table.f-table tr th label,
table.f-table tr td label {
  font-family: "FuturaPT-heavy";
  font-size: 16px;
  font-weight: normal;
  color: #000;
  text-transform: uppercase;
}

/* FMIC Badges */

.f-badge {
  width: 32px;
  height: 32px;
  display: inline-block;
  cursor: pointer;
}
.f-badge:hover {
  transition: all 0.2s ease;
  filter: brightness(160%);
}
.f-badge.f-sm {
  width: 32px;
  height: 32px;
}
.f-badge.f-lg {
  width: 37px;
  height: 37px;
}

.f-badge:before {
  content: " ";
  background: url(../src/images/fmic-icon-vector.svg) no-repeat;
  display: inline-block;
  background-position: -128px -64px;
  background-size: auto 128px;
  width: 32px;
  height: 32px;
}

.f-badge.f-sm:before {
  background-size: auto 128px;
  background-position-y: -63.5px;
  width: 32px;
  height: 32px;
}
.f-badge.f-lg:before {
  background-size: auto 148px;
  background-position-y: -74px;
  width: 37px;
  height: 37px;
}

/* position 3 */
.f-badge.f-free-shipping:before,
.f-badge.f-sm.f-free-shipping:before {
  background-position-x: -96px;
}
.f-badge.f-lg.f-free-shipping:before {
  background-position-x: -111px;
}

/* position 4 */
.f-badge.f-previous-order:before,
.f-badge.f-sm.f-previous-order:before {
  background-position-x: -128px;
}
.f-badge.f-lg.f-previous-order:before {
  background-position-x: -148px;
}

/* position 5 */
.f-badge.f-top-seller:before,
.f-badge.f-sm.f-top-seller:before {
  background-position-x: -160px;
}
.f-badge.f-lg.f-top-seller:before {
  background-position-x: -185px;
}

/* position 6 */
.f-badge.f-open-order:before,
.f-badge.f-sm.f-open-order:before {
  background-position-x: -192px;
}
.f-badge.f-lg.f-open-order:before {
  background-position-x: -222px;
}

/* position 7 */
.f-badge.f-special:before,
.f-badge.f-sm.f-special:before {
  background-position-x: -224px;
}
.f-badge.f-lg.f-special:before {
  background-position-x: -259px;
}

/* position 8 */
.f-badge.f-bundle-package:before,
.f-badge.f-sm.f-bundle-package:before {
  background-position-x: -256px;
}
.f-badge.f-lg.f-bundle-package:before {
  background-position-x: -296px;
}

/* position 9 */
.f-badge.f-marketing-assets:before,
.f-badge.f-sm.f-marketing-assets:before {
  background-position-x: -288px;
}
.f-badge.f-lg.f-marketing-assets:before {
  background-position-x: -333px;
}

/* position 10 */
.f-badge.f-warning:before,
.f-badge.f-sm.f-warning:before {
  background-position-x: -320px;
}
.f-badge.f-lg.f-warning:before {
  background-position-x: -370px;
}

/* position 11 */
.f-badge.f-new:before,
.f-badge.f-sm.f-new:before {
  background-position-x: -352px;
}
.f-badge.f-lg.f-new:before {
  background-position-x: -407px;
}

/* position 12 */
.f-badge.f-embargo:before,
.f-badge.f-sm.f-embargo:before {
  background-position-x: -384px;
}
.f-badge.f-lg.f-embargo:before {
  background-position-x: -444px;
}

/* position 23 */
.f-badge.f-replaced-by:before,
.f-badge.f-sm.f-replaced-by:before {
  background-position-x: -608px;
}

.f-badge.f-lg.f-replaced-by:before {
  background-position-x: -703px;
}

/* Widges Manager Widgets
 */

.widgets-manager {
  clear: both;
}

.widgets-manager .widgets-container {
  clear: both;
}

.widgets-manager .widgets-container .widget {
  margin-bottom: 10px;
  max-height: 374px;
}

.widgets-manager .widgets-container .in-widget {
  background-color: #fff;
  border: solid 1px #ccc;
  max-height: 337px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

@media (max-width: 992px) {
  .widgets-manager .widgets-container .widget {
    max-height: initial;
  }
  .widgets-manager .widgets-container .in-widget {
    max-height: initial;
  }
}

.widgets-manager .widgets-container .in-widget .tab-body {
  padding: 0;
}

.widgets-manager .widgets-container article > header {
  border-color: #404040 !important;
  background: #404040;
  color: #fff;
}

.widgets-manager .widgets-container article > header h2 {
  padding: 0 10px 0 0;
  margin: 0;
}

.widgets-manager .widgets-container article > header a {
  color: #fff;
  font-size: 14px;
  position: relative;
  margin: 0;
  line-height: 34px;
  font-weight: 400;
  letter-spacing: 0;
}

.widgets-manager .widgets-container .data-row,
.widgets-manager .widgets-container .data-header {
  margin-left: 0;
  margin-right: 0;
}

.widgets-manager .widgets-container .saved-searches-list {
  margin-left: 0;
  margin-right: 0;
}

.widgets-manager .widgets-container .widget-toolbar {
  padding: 8px 10px;
  height: initial;
  border-bottom: 1px solid #ccc;
  background: #fafafa;
}

.widgets-manager .widgets-container .widget-icon i {
  font-size: 16px;
}

.widgets-manager .widgets-container .widget-fullscreen {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  margin: 0;
  padding: 0;
  max-height: initial;
}

.widgets-manager .widgets-container .widget-fullscreen > div,
.widgets-manager .widgets-container .widget-fullscreen > div > div,
.widgets-manager .widgets-container .widget-fullscreen .in-widget {
  height: 100%;
  max-height: initial;
}

.widgets-manager .widgets-container .widget-empty {
  border: dashed 2px #ccc;
  padding: 20px 0;
  margin: 10px 0;
  color: #888;
  text-align: center;
  border-radius: 5px;
}

.widget-manager-btn {
  margin: 2px 0;
}

.widget-carousel {
  min-width: 300px;
  border: solid 1px #888;
  padding: 0;
  border-radius: 5px 0 5px 5px;
  background-color: #fff;
  box-shadow: 5px 5px 5px #333;
  position: absolute;
  right: 13px;
  z-index: 11;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
}

.widget-carousel .widget-items-container {
  border-bottom: solid 1px #ccc;
  padding: 5px 10px;
}

.widget-carousel .widget-items-container header {
  background-color: #ddd;
  padding: 5px 9px;
  margin: 0;
}

.widget-carousel .widget-items-container header h5 {
  font-size: 14px;
  margin: 0;
}

.widget-carousel .widget-items-controls {
  padding: 10px;
  background-color: #eee;
  border-radius: 0 0 5px 5px;
}

.widget-carousel .widget-empty {
  cursor: pointer;
  height: 40px;
  padding: 12px;
  text-align: center;
  color: #888;
}

.widget-carousel .widget-item {
  cursor: pointer;
  height: 30px;
  padding: 5px;
}

.widget-carousel .widget-item.selected {
  border: solid 1px transparent !important;
  color: #3276b1;
}

.widget-carousel .widget-item.dragging-before {
  border-top: solid 1px #888 !important;
}

.widget-carousel .widget-item.dragging-after {
  border-bottom: solid 1px #888 !important;
}

main-menu nav.dragging ul li span,
main-menu nav.dragging ul li i,
main-menu nav.dragging ul li.reset-menu {
  pointer-events: none;
}

.menu-on-top aside#left-panel main-menu.dragging nav > ul > li > ul {
  display: none !important;
}

.menu-on-top aside#left-panel main-menu.dragging nav > ul > li#moreMenu > ul {
  display: block !important;
}

.menu-on-top
  aside#left-panel
  main-menu.dragging
  nav
  > ul
  > li#moreMenu
  > ul
  > li
  > ul {
  display: none;
}

main-menu.dragging nav > ul > li#moreMenu {
  background: #fff;
  background: -moz-linear-gradient(top, #cfcfcf 0, #fff 66%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #cfcfcf),
    color-stop(66%, #fff)
  );
  background: -webkit-linear-gradient(top, #cfcfcf 0, #fff 66%);
  background: -o-linear-gradient(top, #cfcfcf 0, #fff 66%);
  background: -ms-linear-gradient(top, #cfcfcf 0, #fff 66%);
  background: linear-gradient(to bottom, #cfcfcf 0, #fff 66%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cfcfcf', endColorstr='#ffffff', GradientType=0);
}

main-menu nav ul li.main-button.dragging-before {
  border-left: solid 1px #fff !important;
}

main-menu nav ul li.main-button.dragging-after {
  border-right: solid 1px #fff !important;
}

main-menu nav ul li.hidden-button.dragging-before {
  border-top: solid 1px #c30202 !important;
}

main-menu nav ul li.hidden-button.dragging-after {
  border-bottom: solid 1px #c30202 !important;
}

.widgets-container .widget.dragging-before:before {
  content: " ";
  display: block;
  text-align: center;
  vertical-align: middle;
  position: absolute;
  height: 20px;
  width: 100%;
  border: dashed 2px #00b3ee;
  background-color: #d6ecff;
  top: -20px;
  left: 0;
  z-index: 1000;
  opacity: 0.5;
}

.widgets-container .widget.dragging-after:after {
  content: " ";
  display: block;
  position: absolute;
  text-align: center;
  vertical-align: middle;
  height: 20px;
  width: 100%;
  border: dashed 2px #00b3ee;
  background-color: #d6ecff;
  left: 0;
  bottom: -20px;
  opacity: 0.5;
}

@media (min-width: 992px) {
  .widgets-container .widget.dragging-before:before {
    content: " ";
    height: 100%;
    width: 20px;
    top: 0;
    left: -10px;
    bottom: initial;
    right: initial;
  }
  .widgets-container .widget.dragging-after:after {
    content: " ";
    height: 100%;
    width: 20px;
    top: 0;
    right: -10px;
    bottom: initial;
    left: initial;
  }
}

.widgets-container label.widget-info {
  margin: 0;
  background-color: #404040;
  padding: 0 10px;
  border-radius: 5px;
}

.widgets-container label.widget-title {
  margin: 0;
  color: #fff;
  font-size: 14px;
  position: relative;
  line-height: 34px;
  font-weight: 400;
  letter-spacing: 0;
}

.widgets-container .widget-footer {
  position: relative;
  bottom: 0;
  border-top: solid 1px #ccc;
  height: 20px;
}

.widget-carousel .widget-item .widget-info {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 10px 0 0;
}

.widget-carousel .widget-item .widget-sort {
  display: inline-block;
  font-size: 16px;
  width: auto;
  text-align: center;
  margin: 0 10px;
}

.widget-carousel .widget-item label {
  width: 20px;
}

widgets-manager .widget-carousel .widget-item .widget-info .hover-message,
widgets-manager
  .widget-carousel
  .widget-item.dragging-before
  .widget-info
  .hover-message,
widgets-manager
  .widget-carousel
  .widget-item.dragging-after
  .widget-info
  .hover-message {
  display: none;
}

widgets-manager
  .widget-carousel
  .widget-item
  .widget-info:hover
  .hover-message {
  display: inline;
}

widgets-manager .widget-items-container {
  border: solid 2px transparent;
}

widgets-manager.dragging .widget-items-container.inactive.dragging-hover,
widgets-manager.dragging .widget-items-container.active.forced.dragging-hover {
  border: dashed 2px #00b3ee;
}

widgets-manager.dragging .widgets-manager .widgets-container {
  background-color: #d6ecff;
  border-radius: 10px;
}

widgets-manager.dragging .widgets-manager .data-header,
widgets-manager.dragging .widgets-manager .data-row,
widgets-manager.dragging .widgets-manager table {
  pointer-events: none;
}

widgets-manager.dragging .widgets-manager .widgets-container .widget-empty {
  border: dashed 2px #00b3ee;
  color: #333;
}

.ellipsis-overflow {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
