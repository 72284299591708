/* DATEPICKER */

.bs-chevron {
  border-style: solid;
  display: block;
  width: 9px;
  height: 9px;
  position: relative;
  border-width: 3px 0px 0 3px;
}
.bs-chevron-up {
  transform: rotate(45deg);
  top: 2px;
}
.bs-chevron-down {
  transform: rotate(-135deg);
  top: -2px;
}
.bs-timepicker-field {
  width: 65px;
  padding: 0.375rem 0.55rem;
}

/* CROPPER */
.img-cropper {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  padding: 5px;
  text-align: center;
}
.img-cropper > div {
  width: 100%;
  position: relative;
}
.img-cropper > div img.ngx-ic-source-image {
  max-width: 100%;
  max-height: 100%;
  transform-origin: center;
}
.img-cropper > div img.ngx-ic-source-image.ngx-ic-draggable {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: grab;
}
.img-cropper .ngx-ic-overlay {
  position: absolute;
  pointer-events: none;
  touch-action: none;
  outline: var(--cropper-overlay-color, white) solid 100vw;
  top: 0;
  left: 0;
}
.img-cropper .ngx-ic-cropper {
  position: absolute;
  display: flex;
  color: #53535c;
  background: transparent;
  outline: rgba(255, 255, 255, 0.3) solid 100vw;
  outline: var(--cropper-outline-color, rgba(255, 255, 255, 0.3)) solid 100vw;
  touch-action: none;
}
@media (orientation: portrait) {
  .img-cropper .ngx-ic-cropper {
    outline-width: 100vh;
  }
}
.img-cropper .ngx-ic-cropper:after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  border: dashed 1px;
  opacity: 0.75;
  color: inherit;
  z-index: 1;
}
.img-cropper .ngx-ic-cropper .ngx-ic-move {
  width: 100%;
  cursor: move;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.img-cropper .ngx-ic-cropper:focus .ngx-ic-move {
  border-color: dodgerblue;
  border-width: 2px;
}
.img-cropper .ngx-ic-cropper .ngx-ic-resize {
  position: absolute;
  display: inline-block;
  line-height: 6px;
  padding: 8px;
  opacity: 0.85;
  z-index: 1;
}
.img-cropper .ngx-ic-cropper .ngx-ic-resize .ngx-ic-square {
  display: inline-block;
  background: #53535c;
  width: 6px;
  height: 6px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: content-box;
}
.img-cropper .ngx-ic-cropper .ngx-ic-resize.ngx-ic-topleft {
  top: -12px;
  left: -12px;
  cursor: nwse-resize;
}
.img-cropper .ngx-ic-cropper .ngx-ic-resize.ngx-ic-top {
  top: -12px;
  left: calc(50% - 12px);
  cursor: ns-resize;
}
.img-cropper .ngx-ic-cropper .ngx-ic-resize.ngx-ic-topright {
  top: -12px;
  right: -12px;
  cursor: nesw-resize;
}
.img-cropper .ngx-ic-cropper .ngx-ic-resize.ngx-ic-right {
  top: calc(50% - 12px);
  right: -12px;
  cursor: ew-resize;
}
.img-cropper .ngx-ic-cropper .ngx-ic-resize.ngx-ic-bottomright {
  bottom: -12px;
  right: -12px;
  cursor: nwse-resize;
}
.img-cropper .ngx-ic-cropper .ngx-ic-resize.ngx-ic-bottom {
  bottom: -12px;
  left: calc(50% - 12px);
  cursor: ns-resize;
}
.img-cropper .ngx-ic-cropper .ngx-ic-resize.ngx-ic-bottomleft {
  bottom: -12px;
  left: -12px;
  cursor: nesw-resize;
}
.img-cropper .ngx-ic-cropper .ngx-ic-resize.ngx-ic-left {
  top: calc(50% - 12px);
  left: -12px;
  cursor: ew-resize;
}
.img-cropper .ngx-ic-cropper .ngx-ic-resize-bar {
  position: absolute;
  z-index: 1;
}
.img-cropper .ngx-ic-cropper .ngx-ic-resize-bar.ngx-ic-top {
  top: -11px;
  left: 11px;
  width: calc(100% - 22px);
  height: 22px;
  cursor: ns-resize;
}
.img-cropper .ngx-ic-cropper .ngx-ic-resize-bar.ngx-ic-right {
  top: 11px;
  right: -11px;
  height: calc(100% - 22px);
  width: 22px;
  cursor: ew-resize;
}
.img-cropper .ngx-ic-cropper .ngx-ic-resize-bar.ngx-ic-bottom {
  bottom: -11px;
  left: 11px;
  width: calc(100% - 22px);
  height: 22px;
  cursor: ns-resize;
}
.img-cropper .ngx-ic-cropper .ngx-ic-resize-bar.ngx-ic-left {
  top: 11px;
  left: -11px;
  height: calc(100% - 22px);
  width: 22px;
  cursor: ew-resize;
}
.img-cropper .ngx-ic-cropper.ngx-ic-round {
  outline-color: transparent;
}
.img-cropper .ngx-ic-cropper.ngx-ic-round:after {
  border-radius: 100%;
  box-shadow: 0 0 0 100vw rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 0 100vw var(--cropper-outline-color, rgba(255, 255, 255, 0.3));
}
@media (orientation: portrait) {
  .img-cropper .ngx-ic-cropper.ngx-ic-round:after {
    box-shadow: 0 0 0 100vh rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 0 100vh
      var(--cropper-outline-color, rgba(255, 255, 255, 0.3));
  }
}
.img-cropper .ngx-ic-cropper.ngx-ic-round .ngx-ic-move {
  border-radius: 100%;
}
.img-cropper.disabled .ngx-ic-cropper .ngx-ic-resize,
.img-cropper.disabled .ngx-ic-cropper .ngx-ic-resize-bar,
.img-cropper.disabled .ngx-ic-cropper .ngx-ic-move {
  display: none;
}
.img-cropper.ngx-ix-hidden {
  display: none;
}

/* KOLKOV TEXT EDITOR */

.angular-editor {
  position: relative;
}
.angular-editor ::ng-deep [contenteditable="true"]:empty:before {
  content: attr(placeholder);
  color: #868e96;
  opacity: 1;
}
.angular-editor .angular-editor-wrapper {
  position: relative;
}

.angular-editor input[type="file"],
.angular-editor input[type="color"] {
  display: none;
}
.angular-editor .angular-editor-wrapper .angular-editor-textarea {
  min-height: 5rem;
  padding: 0.5rem 0.8rem 1rem 0.8rem;
  border: 1px solid #ddd;
  background-color: transparent;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.angular-editor
  .angular-editor-wrapper
  .angular-editor-textarea
  ::ng-deep
  blockquote {
  margin-left: 1rem;
  border-left: 0.2em solid #dfe2e5;
  padding-left: 0.5rem;
}
.angular-editor .angular-editor-wrapper ::ng-deep p {
  margin-bottom: 0;
}
.angular-editor .angular-editor-wrapper .angular-editor-placeholder {
  display: none;
  position: absolute;
  top: 0;
  padding: 0.5rem 0.8rem 1rem 0.9rem;
  color: #6c757d;
  opacity: 0.75;
}
.angular-editor
  .angular-editor-wrapper.show-placeholder
  .angular-editor-placeholder {
  display: block;
}
.angular-editor .angular-editor-wrapper.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.angular-editor-textarea {
  min-height: 150px;
  overflow: auto;
  margin-top: 5px;
  resize: vertical;
}
.angular-editor-textarea:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
  width: 8px;
  height: 8px;
  cursor: nwse-resize;
  background-color: rgba(255, 255, 255, 0.5);
}
.angular-editor-toolbar {
  font: 100 14px/15px Roboto, Arial, sans-serif;
  background-color: #f5f5f5;
  font-size: 0.8rem;
  padding: 0.2rem;
  border: 1px solid #ddd;
}
.angular-editor-toolbar .angular-editor-toolbar-set {
  display: none;
  margin-right: 5px;
  vertical-align: baseline;
}
.angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button {
  background-color: white;
  vertical-align: middle;
  border: 1px solid #ddd;
  padding: 0.4rem;
  min-width: 2rem;
  float: left;
}
.angular-editor-toolbar
  .angular-editor-toolbar-set
  .angular-editor-button:hover {
  cursor: pointer;
  background-color: #f1f1f1;
  transition: 0.2s ease;
}
.angular-editor-toolbar
  .angular-editor-toolbar-set
  .angular-editor-button:focus,
.angular-editor-toolbar
  .angular-editor-toolbar-set
  .angular-editor-button.focus {
  outline: 0;
}
.angular-editor-toolbar
  .angular-editor-toolbar-set
  .angular-editor-button:disabled {
  background-color: #f5f5f5;
  pointer-events: none;
  cursor: not-allowed;
}
.angular-editor-toolbar
  .angular-editor-toolbar-set
  .angular-editor-button:disabled
  > .color-label {
  pointer-events: none;
  cursor: not-allowed;
}
.angular-editor-toolbar
  .angular-editor-toolbar-set
  .angular-editor-button:disabled
  > .color-label.foreground
  :after {
  background: #555;
}
.angular-editor-toolbar
  .angular-editor-toolbar-set
  .angular-editor-button:disabled
  > .color-label.background {
  background: #555;
}
.angular-editor-toolbar
  .angular-editor-toolbar-set
  .angular-editor-button.active {
  background: #fff5b9;
}
.angular-editor-toolbar
  .angular-editor-toolbar-set
  .angular-editor-button.active:hover {
  background-color: #fffa98;
}
.angular-editor-toolbar .angular-editor-toolbar-set select {
  font-size: 11px;
  width: 90px;
  vertical-align: middle;
  background-color: transparent;
  border: 0.5px solid rgba(255, 255, 255, 0);
  border-radius: 5px;
  outline: none;
  padding: 0.4rem;
  cursor: pointer;
}
.angular-editor-toolbar .angular-editor-toolbar-set .select-heading {
  display: inline-block;
  width: 90px;
}
@supports not (-moz-appearance: none) {
  .angular-editor-toolbar .angular-editor-toolbar-set .select-heading optgroup {
    font-size: 12px;
    background-color: #f4f4f4;
    padding: 5px;
  }
  .angular-editor-toolbar .angular-editor-toolbar-set .select-heading option {
    border: 1px solid;
    background-color: white;
  }
  .angular-editor-toolbar .angular-editor-toolbar-set .select-heading .default {
    font-size: 16px;
  }
  .angular-editor-toolbar .angular-editor-toolbar-set .select-heading .h1 {
    font-size: 24px;
  }
  .angular-editor-toolbar .angular-editor-toolbar-set .select-heading .h2 {
    font-size: 20px;
  }
  .angular-editor-toolbar .angular-editor-toolbar-set .select-heading .h3 {
    font-size: 16px;
  }
  .angular-editor-toolbar .angular-editor-toolbar-set .select-heading .h4 {
    font-size: 15px;
  }
  .angular-editor-toolbar .angular-editor-toolbar-set .select-heading .h5 {
    font-size: 14px;
  }
  .angular-editor-toolbar .angular-editor-toolbar-set .select-heading .h6 {
    font-size: 13px;
  }
  .angular-editor-toolbar .angular-editor-toolbar-set .select-heading .div {
    font-size: 12px;
  }
  .angular-editor-toolbar .angular-editor-toolbar-set .select-heading .pre {
    font-size: 12px;
  }
}
.angular-editor-toolbar .angular-editor-toolbar-set .select-heading:disabled {
  background-color: #f5f5f5;
  pointer-events: none;
  cursor: not-allowed;
}
.angular-editor-toolbar .angular-editor-toolbar-set .select-heading:hover {
  cursor: pointer;
  background-color: #f1f1f1;
  transition: 0.2s ease;
}
.angular-editor-toolbar .angular-editor-toolbar-set .select-font {
  display: inline-block;
  width: 90px;
}
@supports not (-moz-appearance: none) {
  .angular-editor-toolbar .angular-editor-toolbar-set .select-font optgroup {
    font-size: 12px;
    background-color: #f4f4f4;
    padding: 5px;
  }
  .angular-editor-toolbar .angular-editor-toolbar-set .select-font option {
    border: 1px solid;
    background-color: white;
  }
}
.angular-editor-toolbar .angular-editor-toolbar-set .select-font:disabled {
  background-color: #f5f5f5;
  pointer-events: none;
  cursor: not-allowed;
}
.angular-editor-toolbar .angular-editor-toolbar-set .select-font:hover {
  cursor: pointer;
  background-color: #f1f1f1;
  transition: 0.2s ease;
}
.angular-editor-toolbar .angular-editor-toolbar-set .select-font-size {
  display: inline-block;
  width: 50px;
}
@supports not (-moz-appearance: none) {
  .angular-editor-toolbar
    .angular-editor-toolbar-set
    .select-font-size
    optgroup {
    font-size: 12px;
    background-color: #f4f4f4;
    padding: 5px;
  }
  .angular-editor-toolbar .angular-editor-toolbar-set .select-font-size option {
    border: 1px solid;
    background-color: white;
  }
  .angular-editor-toolbar .angular-editor-toolbar-set .select-font-size .size1 {
    font-size: 10px;
  }
  .angular-editor-toolbar .angular-editor-toolbar-set .select-font-size .size2 {
    font-size: 12px;
  }
  .angular-editor-toolbar .angular-editor-toolbar-set .select-font-size .size3 {
    font-size: 14px;
  }
  .angular-editor-toolbar .angular-editor-toolbar-set .select-font-size .size4 {
    font-size: 16px;
  }
  .angular-editor-toolbar .angular-editor-toolbar-set .select-font-size .size5 {
    font-size: 18px;
  }
  .angular-editor-toolbar .angular-editor-toolbar-set .select-font-size .size6 {
    font-size: 20px;
  }
  .angular-editor-toolbar .angular-editor-toolbar-set .select-font-size .size7 {
    font-size: 22px;
  }
}
.angular-editor-toolbar .angular-editor-toolbar-set .select-font-size:disabled {
  background-color: #f5f5f5;
  pointer-events: none;
  cursor: not-allowed;
}
.angular-editor-toolbar .angular-editor-toolbar-set .select-font-size:hover {
  cursor: pointer;
  background-color: #f1f1f1;
  transition: 0.2s ease;
}
.angular-editor-toolbar .angular-editor-toolbar-set .select-custom-style {
  display: inline-block;
  width: 90px;
}
@supports not (-moz-appearance: none) {
  /*.size1 {
      font-size: 10px;
   }
    .size2 {
      font-size: 12px;
   }
    .size3 {
      font-size: 14px;
   }
    .size4 {
      font-size: 16px;
   }
    .size5 {
      font-size: 18px;
   }
    .size6 {
      font-size: 20px;
   }
    .size7 {
      font-size: 22px;
   }
   */
  .angular-editor-toolbar
    .angular-editor-toolbar-set
    .select-custom-style
    optgroup {
    font-size: 12px;
    background-color: #f4f4f4;
    padding: 5px;
  }
  .angular-editor-toolbar
    .angular-editor-toolbar-set
    .select-custom-style
    option {
    border: 1px solid;
    background-color: white;
  }
}
.angular-editor-toolbar
  .angular-editor-toolbar-set
  .select-custom-style:disabled {
  background-color: #f5f5f5;
  pointer-events: none;
  cursor: not-allowed;
}
.angular-editor-toolbar .angular-editor-toolbar-set .select-custom-style:hover {
  cursor: pointer;
  background-color: #f1f1f1;
  transition: 0.2s ease;
}
.angular-editor-toolbar .angular-editor-toolbar-set .color-label {
  position: relative;
  cursor: pointer;
}
.angular-editor-toolbar .angular-editor-toolbar-set .background {
  font-size: smaller;
  background: #1b1b1b;
  color: white;
  padding: 3px;
}
.angular-editor-toolbar .angular-editor-toolbar-set .foreground :after {
  position: absolute;
  content: "";
  left: -1px;
  top: auto;
  bottom: -3px;
  right: auto;
  width: 15px;
  height: 2px;
  z-index: 0;
  background: #1b1b1b;
}
.angular-editor-toolbar
  .angular-editor-toolbar-set:not([style*="display:none"]):not([style*="display: none"]) {
  display: inline-block;
}
/* AE Select */

.select-button {
  display: inline-block;
}
.select-button.disabled {
  cursor: pointer;
  background-color: #f1f1f1;
  transition: 0.2s ease;
}

/* AE Button */
.ae-font.ae-picker {
  color: #444;
  display: inline-block;
  float: left;
  width: 100%;
  position: relative;
  vertical-align: middle;
}
.ae-font .ae-picker-label {
  cursor: pointer;
  display: inline-block;
  height: 100%;
  padding-left: 8px;
  padding-right: 10px;
  position: relative;
  width: 100%;
  line-height: 26px;
  vertical-align: middle;
  font-size: 85%;
  text-align: left;
  background-color: white;
  min-width: 2rem;
  float: left;
  border: 1px solid #ddd;
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
}
.ae-font .ae-picker-label:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 1), #fff 100%);
}
.ae-font .ae-picker-label:focus {
  outline: none;
}
.ae-font .ae-picker-label:hover {
  cursor: pointer;
  background-color: #f1f1f1;
  transition: 0.2s ease;
}
.ae-font .ae-picker-label:hover:before {
  background: linear-gradient(to right, #f5f5f5 100%, #fff 100%);
}
.ae-font .ae-picker-label:disabled {
  background-color: #f5f5f5;
  pointer-events: none;
  cursor: not-allowed;
}
.ae-font .ae-picker-label:disabled:before {
  background: linear-gradient(to right, #f5f5f5 100%, #fff 100%);
}
.ae-font .ae-picker-label svg {
  position: absolute;
  margin-top: -9px;
  right: 0;
  top: 50%;
  width: 18px;
}
.ae-font .ae-picker-label svg:not(:root) {
  overflow: hidden;
}
.ae-font .ae-picker-label svg .ae-stroke {
  fill: none;
  stroke: #444;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
}
.ae-font .ae-picker-options {
  background-color: #fff;
  display: none;
  min-width: 100%;
  position: absolute;
  white-space: nowrap;
  z-index: 3;
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px;
}
.ae-font .ae-picker-options .ae-picker-item {
  cursor: pointer;
  display: block;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 5px;
  z-index: 3;
  text-align: left;
  background-color: transparent;
  min-width: 2rem;
  width: 100%;
  border: 0 solid #ddd;
}
.ae-font .ae-picker-options .ae-picker-item.selected {
  color: #06c;
  background-color: #fff4c2;
}
.ae-font .ae-picker-options .ae-picker-item.focused {
  background-color: #fffa98;
}
.ae-font .ae-picker-options .ae-picker-item:hover {
  background-color: #fffa98;
}
.ae-font.ae-expanded {
  display: block;
  margin-top: -1px;
  z-index: 1;
}
.ae-font.ae-expanded .ae-picker-label {
  color: #ccc;
  z-index: 2;
}
.ae-font.ae-expanded .ae-picker-label svg {
  color: #ccc;
  z-index: 2;
}
.ae-font.ae-expanded .ae-picker-label svg .ae-stroke {
  stroke: #ccc;
}
.ae-font.ae-expanded .ae-picker-options {
  display: block;
  margin-top: -1px;
  top: 100%;
  z-index: 3;
  border-color: #ccc;
}
